import { Pagination, Table, Text } from "@mantine/core";
import { useContext } from "react";
import { IngredientContext } from "../../../pages/Ingredients/Ingredients.jsx";
import { doesArrayHaveData } from "../../../utils/Array.utils.js";
import LoadingRows from "../../Loading/LoadingRows.jsx";
import IngredientRow from "../../Rows/Ingredients/IngredientRow.jsx";
import { NoRowsFound } from "../../Rows/NoRowsFound/NoRowsFound.jsx";
import styles from "./IngredientTable.module.css";

const IngredientTable = ({ isLoading, total, activePage, setActivePage }) => {
  const ingredients = useContext(IngredientContext);

  /**
   * Create the table header
   */
  const tableHead = (
    <tr>
      <th className={styles.indicatorHeader} />
      <th>
        <Text className={styles.headerText}>Name</Text>
      </th>
      <th>
        <Text className={styles.headerText}>Supplier</Text>
      </th>
      <th>
        <Text className={styles.headerText}>Health</Text>
      </th>
      <th>
        <Text className={styles.headerText}>Allergens</Text>
      </th>
      <th>
        <Text className={styles.headerText}>Cost</Text>
      </th>
      <th>
        <Text className={styles.headerText}>Unit Of Measure</Text>
      </th>
      <th>
        <Text className={styles.headerText}>Updated</Text>
      </th>
      <th className={styles.menuHeader} />
    </tr>
  );

  /**
   * Create the table rows
   */
  const tableRows = isLoading ? (
    <LoadingRows numColumns={8} numRows={20} hidden={[0]} />
  ) : doesArrayHaveData(ingredients) ? (
    ingredients.map((ingredient) => {
      return (
        <IngredientRow key={ingredient.ingredient_id} ingredient={ingredient} />
      );
    })
  ) : (
    <NoRowsFound numberOfColumns={8} />
  );

  // We call this 'tableFoot', but we're not putting it in the table's footer.
  // This is because the footer is still constrained by the column widths like the rest of the table.
  // Instead, we're putting it in the table caption, because we no longer have those width constraints
  // and we can still make the caption look like a footer.
  const tableFoot = (
    <div className={styles.paginationContainer}>
      <Pagination
        classNames={{ control: styles.activePage }}
        size="sm"
        page={activePage}
        boundaries={2}
        total={total}
        onChange={(value) => {
          setActivePage(value);
          document.getElementById("scrollableContainer").scrollTo(0, 0);
        }}
      />
    </div>
  );

  return (
    <div className={styles.scrollableContainer} id="scrollableContainer">
      <Table captionSide="bottom" className={styles.table}>
        <thead className={styles.head}>{tableHead}</thead>
        <tbody className={styles.body}>{tableRows}</tbody>
        <caption className={styles.caption}>{tableFoot}</caption>
      </Table>
    </div>
  );
};

export default IngredientTable;
