import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import styles from "../../App/App.module.css";
import { getLocalStorage } from "../../utils/LocalStorage.utils.js";
import Header from "../Header/Header";
import NavBar from "../NavBar/NavBar";

const PrivateRoutes = () => {
  const [search, setSearch] = useState();

  const sessionData = getLocalStorage("sessionData");
  const shouldRedirect = !sessionData || sessionData === "null";

  return !shouldRedirect ? (
    <div>
      <NavBar />
      <Header setSearch={setSearch} />
      <div className={styles.page}>
        <Outlet context={[search]} />
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
