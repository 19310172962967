const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";

  const cleanInput = phoneNumber.replace(/\D/g, "").substring(0, 15);
  let output = "";

  if (cleanInput.length <= 3) {
    // Format only the area code part
    output = `(${cleanInput}`;
  } else if (cleanInput.length <= 6) {
    // Format the area code and the first part of the number
    const first = cleanInput.substring(0, 3);
    const middle = cleanInput.substring(3);
    output = `(${first}) ${middle}`;
  } else if (cleanInput.length <= 10) {
    // Format the full number without extension
    const first = cleanInput.substring(0, 3);
    const middle = cleanInput.substring(3, 6);
    const last = cleanInput.substring(6);
    output = `(${first}) ${middle}-${last}`;
  } else {
    // Format the full number with extension
    const first = cleanInput.substring(0, 3);
    const middle = cleanInput.substring(3, 6);
    const last = cleanInput.substring(6, 10);
    const ext = cleanInput.substring(10);
    output = `(${first}) ${middle}-${last} #${ext}`;
  }

  return output;
};

export { formatPhoneNumber };
