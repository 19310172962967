import { Group, Menu, Text, TextInput, UnstyledButton } from "@mantine/core";
import {
  AccountCircle,
  ExpandMore,
  Lock,
  Logout,
  Search,
} from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { Link } from "react-router-dom";
import Auth from "../../utils/Auth/Auth";
import { getLocalStorage } from "../../utils/LocalStorage.utils.js";
import ImageWithPlaceholder from "../ImageWithPlaceholder/ImageWithPlaceholder";
import { ImagesModal } from "../Modals/ImagesModal.jsx";
import styles from "./Header.module.css";

const Header = ({ setSearch }) => {
  const [opened, setOpened] = useState(false);
  const auth = new Auth();

  const sessionData = getLocalStorage("sessionData");
  const encodedIdToken = sessionData.idToken.jwtToken;
  const { sub, email, given_name, family_name } = jwt_decode(encodedIdToken);

  let searchTerm = "";

  // When the user types a search term and hit's 'Enter', then we search
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setSearch(searchTerm);
    }
  };

  return (
    <div className={styles.header}>
      <Group justify="space-between">
        <TextInput
          className={styles.search}
          placeholder="Search..."
          leftSection={<SvgIcon component={Search} />}
          visibleFrom="xs"
          onChange={(event) => {
            searchTerm = event.target.value.trim();
            if (event.target.value.length === 0) setSearch("");
          }}
          onKeyDown={handleKeyDown}
        />

        <ImagesModal
          entityId={sub}
          opened={opened}
          onClose={() => setOpened(false)}
        />
        <Menu closeOnClickOutside>
          <Menu.Target className={styles.menuTarget}>
            <UnstyledButton className={styles.imageButton}>
              <Group>
                <ImageWithPlaceholder
                  avatarText={`${given_name || null} ${family_name || null}`}
                  imageKey={btoa(sub)}
                />
                <div style={{ flex: 1 }}>
                  <Text>{`${given_name || null} ${family_name || null}`}</Text>
                  <Text c="dimmed" size="xs">
                    {email || ""}
                  </Text>
                </div>
                <SvgIcon component={ExpandMore} />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              className={styles.menuItem}
              leftSection={<SvgIcon component={AccountCircle} />}
              onClick={() => setOpened(true)}
            >
              Upload Profile Picture
            </Menu.Item>
            <Menu.Item
              className={styles.menuItem}
              leftSection={<SvgIcon component={Lock} />}
            >
              Change Password
            </Menu.Item>
            <Menu.Item
              className={styles.menuItem}
              leftSection={<SvgIcon component={Logout} />}
              component={Link}
              to="/login"
              onClick={auth.logout}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </div>
  );
};

export default Header;
