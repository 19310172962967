import {
  Category,
  Healing,
  Interests,
  LocalDrink,
  LocalShipping,
} from "@mui/icons-material";

const pages = [
  {
    name: "Drinks",
    icon: LocalDrink,
    path: "/drinks",
  },
  {
    name: "Ingredients",
    icon: Interests,
    path: "/ingredients",
  },
  {
    name: "Suppliers",
    icon: LocalShipping,
    path: "/suppliers",
  },
  {
    name: "Categories",
    icon: Category,
    path: "/categories",
    children: [
      {
        name: "Allergens",
        icon: Healing,
        path: "/allergens",
      },
      {
        name: "Drinks",
        icon: LocalDrink,
        path: "/drink-categories",
      },
      {
        name: "Ingredients",
        icon: Interests,
        path: "/ingredient-categories",
      },
    ],
  },
];

export default pages;
