type ShortAddress = {
  city?: string;
  state_name?: string;
  state_id?: string;
  postal_code?: string;
};

function titleCase(str: string): string {
  if (!str) return "";
  return str.toLowerCase().replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
}

// Takes the first letter of each meaningful word, capitalizes
// them, and puts them together
function createAcronym(sentence: string, limit: number = 2): string {
  limit = limit ? limit : 2;
  // List of filler words to remove
  const fillerWords = new Set(["of", "the", "an", "a"]);

  // Split the sentence into words, filter out filler words, and create the acronym
  return sentence
    .split(" ")
    .filter((word) => !fillerWords.has(word.toLowerCase()))
    .map((word) => word.charAt(0).toUpperCase())
    .join("")
    .substring(0, limit);
}

function isStringBetweenLength(str: string, min: number, max: number): boolean {
  return (
    !!str && typeof str === "string" && str.length >= min && str.length <= max
  );
}

function createName(firstName: string, lastName: string): string {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else if (lastName) {
    return lastName;
  }
  return "";
}

// Creates a short address string from the given address object
// Filters out empty fields
function createShortAddressString(address: ShortAddress): string {
  const { city, state_name, state_id, postal_code } = address;

  const cityString = city ? `${titleCase(city)}` : "";
  const stateString = state_id ? `${state_id.toUpperCase()}` : state_name ? `${titleCase(state_name)}` : "";
  const postalCodeString = postal_code ? postal_code : "";

  // Build address with proper punctuation
  let result = cityString;
  if (stateString && result) result += `, ${stateString}`;
  else if (stateString) result += stateString;
  if (postalCodeString && result) result += `. ${postalCodeString}`;
  else if (postalCodeString) result += postalCodeString;

  return result;
}

export { createAcronym, createName, createShortAddressString, isStringBetweenLength, titleCase };
