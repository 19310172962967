import { useDisclosure } from "@mantine/hooks";
import { createContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { getEntities, getEntitiesCount } from "../../utils/Api.utils.js";
import { calculateOffset } from "../../utils/PageIndex.utils.js";
import IngredientCategoryModal from "../../components/Modals/IngredientCategoryModal.jsx";
import PageTitle from "../../components/PageTitle/PageTitle.jsx";
import IngredientCategoryTable from "../../components/Tables/IngredientCategories/IngredientCategoryTable.jsx";

export const IngredientCategoryContext = createContext();
export const ModalContext = createContext();

const IngredientCategories = () => {
  // IngredientCategory state
  const [search] = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [ingredientCategories, setIngredientCategories] = useState([]);
  const [count, setCount] = useState(1);
  const [activePage, setActivePage] = useState(1);

  // Modal state
  const [opened, { open, close }] = useDisclosure(false);
  const [modalInfo, setModalInfo] = useState();

  // Get the ingredient categories list when the page loads,
  // anytime a search is entered/changed, or the page changes.
  useEffect(() => {
    getIngredientCategories();
    // eslint-disable-next-line
  }, [search, activePage]);

  // Get the count of ingredient categories when the page loads
  // or anytime a search is entered/changed
  useEffect(() => {
    getIngredientCategoryCount();
    // eslint-disable-next-line
  }, [search]);

  // Gets and sets a list of ingredient categories.
  const getIngredientCategories = () => {
    setIsLoading(true);
    getEntities({
      entityName: "ingredient-categories",
      search: search,
      offset: calculateOffset(activePage),
      limit: 100,
      isActive: null,
    }).then((data) => {
      setIngredientCategories(data);
      setIsLoading(false);
    });
  };

  // Gets and sets the total count of Ingredient Categories.
  const getIngredientCategoryCount = () => {
    getEntitiesCount({
      entitiesName: "ingredient-categories",
      search: search,
    }).then((data) => {
      setCount(data.count);
    });
  };

  return (
    <IngredientCategoryContext.Provider value={ingredientCategories}>
      <ModalContext.Provider
        value={{
          opened,
          open,
          close,
          modalInfo,
          setModalInfo,
          reload: getIngredientCategories,
        }}
      >
        <PageTitle
          title="Ingredient Category List"
          buttonLabel="Add New"
          context={ModalContext}
        />
        <IngredientCategoryModal />
        <IngredientCategoryTable
          isLoading={isLoading}
          total={Math.ceil(count / 100)}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </ModalContext.Provider>
    </IngredientCategoryContext.Provider>
  );
};

export default IngredientCategories;
