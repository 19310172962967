import { Image, NavLink } from "@mantine/core";
import { SvgIcon } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoFull from "../../assets/chug-logo-blue.png";
import pages from "../../pages/pages";
import styles from "./NavBar.module.css";

const NavBar = () => {
  const [active, setActive] = useState([""]);

  useEffect(() => {
    setActive([formatName(window.location.pathname)]);
  }, []);

  const formatName = (name) => {
    return name.replace("/", "").replace("-", " ").toLowerCase();
  };

  return (
    <nav className={styles.navbar}>
      <Image src={logoFull} className={styles.logo} />
      <ul className={styles.listParent}>
        {pages.map(({ name: parentName, icon, path: parentPath, children }) => {
          const isActive = active.includes(formatName(parentName));
          const hasChildren = children && children.length > 0;
          return (
            <NavLink
              key={parentName}
              variant="subtle"
              className={styles.navlink}
              classNames={{
                root: styles.navItemRoot,
                label: styles.navItemText,
              }}
              leftSection={<SvgIcon component={icon} />}
              active={isActive}
              noWrap
              childrenOffset={35}
              component={Link}
              to={parentPath}
              label={parentName}
              defaultOpened={false}
              onClick={() => {
                setActive(formatName(parentName));
              }}
            >
              {hasChildren
                ? children.map(({ name: childName, icon, path }) => {
                    const isChildActive = active.includes(formatName(path));
                    return (
                      <NavLink
                        key={childName}
                        variant="subtle"
                        className={styles.navlink}
                        classNames={{
                          root: styles.childNavItemRoot,
                          label: styles.navItemText,
                        }}
                        leftSection={<SvgIcon component={icon} />}
                        active={isChildActive}
                        noWrap
                        component={Link}
                        to={path}
                        label={childName}
                        onClick={() =>
                          setActive([formatName(parentPath), formatName(path)])
                        }
                      />
                    );
                  })
                : null}
            </NavLink>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavBar;
