import { isStringBetweenLength } from "../../utils/String.utils.ts";

const isValid = async (ingredientCategoryData) => {
  return new Promise((resolve) => {
    const name =
      ingredientCategoryData && ingredientCategoryData.name
        ? ingredientCategoryData.name
        : null;

    if (isStringBetweenLength(name, 2, 50)) {
      resolve(true);
    } else {
      resolve("Ingredient Category name is invalid");
    }
  });
};

export default isValid;
