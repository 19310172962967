import { Button, Flex, Modal, Text } from "@mantine/core";
import styles from "./Modal.module.css";

const DeleteConfirmation = ({ opened, close, handleDelete, isLoading }) => {
  return (
    <Modal
      centered
      classNames={{ title: styles.modalHeader }}
      shadow="md"
      title={"Delete Confirmation"}
      opened={opened}
      onClose={close}
    >
      <Text>Are you sure you want to delete?</Text>
      <Flex className={styles.buttonContainer} gap="md">
        <Button variant="outline" onClick={close}>
          Cancel
        </Button>
        <Button
          className={styles.deleteButton}
          onClick={handleDelete}
          loading={isLoading}
        >
          Delete
        </Button>
      </Flex>
    </Modal>
  );
};

export default DeleteConfirmation;
