import { Flex, Text } from "@mantine/core";
import { Circle, MailOutline, PhoneOutlined } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ModalContext } from "../../../pages/Suppliers/Suppliers.jsx";
import { deleteEntity, updateEntity } from "../../../utils/Api.utils.js";
import { formatDate } from "../../../utils/Date.utils.js";
import {
  createName,
  createShortAddressString,
} from "../../../utils/String.utils.ts";
import { ActionsMenu } from "../../ActionsMenu/ActionsMenu.js";
import ImageWithPlaceholder from "../../ImageWithPlaceholder/ImageWithPlaceholder.js";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../Notifications/Notifications.jsx";
import styles from "./SupplierRow.module.css";

const SupplierRow = ({ supplier }) => {
  const { reload } = useContext(ModalContext);

  // This is used so that, after activating/deactivating a row,
  // the indicator immediately shows/hides without a page refresh.
  const [isActive, setIsActive] = useState(supplier.is_active);

  /**
   * This acts as an active/inactive toggle for the supplier
   */
  const handleActiveStatus = () => {
    const entityName = "suppliers";
    updateEntity({
      entityName,
      entityId: supplier.supplier_id,
      body: { is_active: !isActive },
    })
      .then((data) => {
        if (data.statusCode === 200) {
          setIsActive((prev) => !prev);
          showSuccessNotification(
            `${supplier.first_name} was successfully ${
              data.is_active ? "activated!" : "deactivated!"
            }`
          );
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(
          `Failed to toggle supplier active status. ${error.message}`
        );
      });
  };

  /**
   * This deletes the supplier
   */
  const handleDelete = async () => {
    return deleteEntity({
      entityName: "suppliers",
      entityId: supplier.supplier_id,
    })
      .then((data) => {
        if (data.statusCode === 200) {
          showSuccessNotification(
            `${supplier.first_name} was successfully deleted!`
          );
          reload();
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(`Failed to delete supplier. ${error.message}`);
      });
  };

  return (
    <tr className={styles.row}>
      <td>
        <SvgIcon
          className={
            isActive ? styles.activeIndicator : styles.inactiveIndicator
          }
          component={Circle}
        />
      </td>
      <td>
        <Flex gap="sm" align="center">
          <ImageWithPlaceholder
            imageKey={supplier.image_key}
            avatarText={supplier.name}
            size={35}
          />
          <Flex direction={"column"}>
            <Text>{supplier.name}</Text>
          </Flex>
        </Flex>
      </td>
      <td>
        <Text>
          {createName(supplier.contact_first_name, supplier.contact_last_name)}
        </Text>
      </td>
      <td>
        <Flex direction={"column"}>
          <Text className={styles.subtext}>{supplier.address_1}</Text>
          <Text className={styles.subtext}>{supplier.address_2}</Text>
          <Text className={styles.subtext}>
            {createShortAddressString({
              city: supplier.city,
              state_id: supplier.state_id,
              postal_code: supplier.postal_code,
            })}
          </Text>
        </Flex>
      </td>
      <td>
        <Flex>
          <SvgIcon className={styles.icon} component={MailOutline} />
          <Link
            className={styles.link}
            onClick={(event) => {
              event.stopPropagation();
              window.location.href = `mailto:${supplier.email}`;
            }}
          >
            {supplier.email}
          </Link>
        </Flex>
        <Flex>
          <SvgIcon className={styles.icon} component={PhoneOutlined} />
          <Link
            className={styles.link}
            onClick={(event) => {
              event.stopPropagation();
              window.location.href = `tel:${supplier.phone_number}`;
            }}
          >
            {supplier.phone_number}
          </Link>
        </Flex>
      </td>
      <td>
        <Text>{formatDate(supplier.created_at)}</Text>
      </td>
      <td>
        <ActionsMenu
          modalInfo={supplier}
          context={ModalContext}
          isActive={isActive}
          handleActiveStatus={handleActiveStatus}
          handleDelete={handleDelete}
        />
      </td>
    </tr>
  );
};

export default SupplierRow;
