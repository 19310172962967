import { showErrorNotification } from "../../components/Notifications/Notifications";

const emailRegex = /\S+@\S+\.\S+/;
const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const noEmailError = "Please provide a valid email.";
const emailRegexError = "The email format is not valid.";
const noCodeError =
  "Please enter the verification code that was sent to the provided email address.";
const noPasswordError = "Please provide a new password.";
const noMatchError = "Passwords do not match.";
const regexError =
  "New password must be 8 characters long and contain an uppercase letter, lower case letter, a number, and a special character.";

const emailValidation = async (email) => {
  return new Promise((resolve) => {
    if (email) {
      let isValid = true;

      if (!emailRegex.test(email)) {
        showErrorNotification(emailRegexError);
        isValid = false;
      }

      resolve(isValid);
    } else {
      showErrorNotification(noEmailError);
      resolve(false);
    }
  });
};

const forgotPasswordValidation = async (code, newPassword, confirmPassword) => {
  return new Promise((resolve) => {
    let isValid = true;

    if (!code) {
      showErrorNotification(noCodeError);
      isValid = false;
    } else if (!newPassword || !confirmPassword) {
      showErrorNotification(noPasswordError);
      isValid = false;
    } else if (newPassword !== confirmPassword) {
      showErrorNotification(noMatchError);
      isValid = false;
    } else if (!passwordRegex.test(newPassword)) {
      showErrorNotification(regexError);
      isValid = false;
    }

    // Other validations go here...

    resolve(isValid);
  });
};

export { emailValidation, forgotPasswordValidation };
