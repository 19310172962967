import { useDisclosure } from "@mantine/hooks";
import { createContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import IngredientModal from "../../components/Modals/IngredientModal.jsx";
import PageTitle from "../../components/PageTitle/PageTitle.jsx";
import IngredientTable from "../../components/Tables/Ingredients/IngredientTable.jsx";
import { getEntities, getEntitiesCount } from "../../utils/Api.utils.js";
import { calculateOffset } from "../../utils/PageIndex.utils.js";

export const IngredientContext = createContext();
export const ModalContext = createContext();

const Ingredients = () => {
  // Ingredient state
  const [search] = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [count, setCount] = useState(1);
  const [activePage, setActivePage] = useState(1);

  // Modal state
  const [opened, { open, close }] = useDisclosure(false);
  const [modalInfo, setModalInfo] = useState();

  // Get the ingredients list when the page loads,
  // anytime a search is entered/changed, or the page changes.
  useEffect(() => {
    getIngredients();
    // eslint-disable-next-line
  }, [search, activePage]);

  // Get the count of ingredients when the page loads
  // or anytime a search is entered/changed
  useEffect(() => {
    getIngredientCount();
    // eslint-disable-next-line
  }, [search]);

  // Gets and sets a list of ingredients.
  const getIngredients = () => {
    setIsLoading(true);
    getEntities({
      entityName: "ingredients",
      search: search,
      offset: calculateOffset(activePage),
      limit: 100,
      isActive: null,
    }).then((data) => {
      setIngredients(data);
      setIsLoading(false);
    });
  };

  // Gets and sets the total count of Ingredients.
  const getIngredientCount = () => {
    getEntitiesCount({
      entitiesName: "ingredients",
      search: search,
    }).then((data) => {
      setCount(data.count);
    });
  };

  return (
    <IngredientContext.Provider value={ingredients}>
      <ModalContext.Provider
        value={{
          opened,
          open,
          close,
          modalInfo,
          setModalInfo,
          reload: getIngredients,
        }}
      >
        <PageTitle
          title="Ingredient List"
          buttonLabel="Add New"
          context={ModalContext}
        />
        <IngredientModal />
        <IngredientTable
          isLoading={isLoading}
          total={Math.ceil(count / 100)}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </ModalContext.Provider>
    </IngredientContext.Provider>
  );
};

export default Ingredients;
