import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
} from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import jwt_decode from "jwt-decode";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../components/Notifications/Notifications";
import {
  getLocalStorage,
  setLocalStorage,
} from "../LocalStorage.utils.js";
import Pool from "./UserPool";

// Set the AWS region
AWS.config.update({
  region: "us-east-2",
});

const notAdminUserError = { message: "This user is not a Chug Admin." };

class Auth {
  constructor() {
    if (!getLocalStorage("sessionData")) {
      setLocalStorage("sessionData", null);
    }
  }

  /**
   * The default authentication flow.
   * Also returns if the user needs to reset their password and can handle the new passord challenge.
   */
  async authenticate(Username, Password, NewPassword) {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
        storage: window.localStorage,
      });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          // If they're not an Admin, prevent login
          if (!this.isAdminUser(data)) {
            this.logout();
            reject(notAdminUserError);
          }

          // If they are an Admin, continue...
          data.new_password_required = false;
          console.log("Login Successful");
          setLocalStorage("sessionData", data);
          resolve(data);
        },
        onFailure: (error) => {
          console.error("Login Failed", error);
          setLocalStorage("sessionData", null);
          reject(error);
        },
        newPasswordRequired: (data) => {
          if (!NewPassword) {
            data.new_password_required = true;
            console.log("New Password Required");
            resolve(data);
          } else {
            // Since the user pool we're using require the given and family names (and since
            // we don't need to provide it here since we're admins) we're just putting dummy
            // data in.
            const dummyNames = { given_name: "Admin", family_name: "User" };
            user.completeNewPasswordChallenge(NewPassword, dummyNames, {
              onSuccess: (result) => {
                console.log("Password Changed");
                setLocalStorage("sessionData", result);
                resolve(result);
              },
              onFailure: (error) => {
                console.error("Failed to change password:", error);
                setLocalStorage("sessionData", null);
                reject(error);
              },
            });
          }
        },
      });
    });
  }

  /**
   * Used when the user is already logged in, but wants to change their password.
   */
  async changePassword(PreviousPassword, ProposedPassword) {
    return new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      user.getSession((error) => {
        if (error) {
          console.error(
            "An error occurred changing the user's password:",
            error
          );
          reject(error);
        }

        user.changePassword(
          PreviousPassword,
          ProposedPassword,
          function (error, result) {
            if (error) {
              reject(error);
            } else if (result) {
              resolve(result);
            }
          }
        );
      });
    });
  }

  /**
   * Tells the backend to send a verification code to the user's email.
   */
  async sendVerificationCode(Username, setForgotPasswordPhase) {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      user.forgotPassword({
        onSuccess: (data) => {
          console.log("Success:", data);
          resolve();
        },
        onFailure: (error) => {
          console.error("Sending Verification Code Failed:", error);
          showErrorNotification(error.message);
          reject();
        },
        inputVerificationCode: (data) => {
          console.log("Verification Code Successfully Sent:", data);
          setForgotPasswordPhase(1); // This phase tells the UI to change to where the user can input the code and the 'new' and 'confirm' passwords
          resolve();
        },
      });
    });
  }

  /**
   * Used in the 'forgot password' flow.
   * After the user enters the verification code (that was sent to their email) and
   * types the new password, this will attempt to reset the password.
   */
  async resetPassword(Username, code, password) {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      user.confirmPassword(code, password, {
        onSuccess: () => {
          showSuccessNotification("Password reset successful!");
          resolve(true);
        },
        onFailure: (error) => {
          showErrorNotification(error.message);
          reject(false);
        },
      });
    });
  }

  /**
   * Gets the ID, ACCESS, or REFRESH token from local storage.
   * Acceptable values are 'idToken', 'accessToken', or 'refreshToken'
   */
  async getSessionToken(tokenType) {
    return new Promise((resolve, reject) => {
      try {
        const session = getLocalStorage("sessionData");
        if (tokenType === "refreshToken") {
          resolve(session[tokenType].token);
        } else {
          resolve(session[tokenType].jwtToken);
        }
      } catch (error) {
        console.error(
          `An error occurred retrieving the ${tokenType} token.`,
          error
        );
        reject(error);
      }
    });
  }

  /**
   * This gets a new session object, using the refresh token, when the id or access token(s) have expired.
   */
  async retrieveNewTokens() {
    return new Promise((resolve, reject) => {
      this.getSessionToken("refreshToken")
        .then((refreshToken) => {
          const token = new CognitoRefreshToken({ RefreshToken: refreshToken });
          const user = Pool.getCurrentUser();

          user.refreshSession(token, (error, session) => {
            if (error) {
              console.error("Token refresh failed", error);
              reject(error);
            } else {
              console.log("Token refresh successful");
              setLocalStorage("sessionData", session);
              resolve(session);
            }
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Logs the user out on the UI as well as with the backend.
   */
  logout() {
    const user = Pool.getCurrentUser();
    if (user) {
      setLocalStorage("sessionData", null);
      user.signOut();
    }
  }

  /**
   * Verifies that the user belongs to the Admin group necessary for access
   * to this Admin application.
   */
  isAdminUser(sessionData) {
    const encodedIdToken = sessionData.idToken.jwtToken;
    const decodedData = jwt_decode(encodedIdToken);

    // If there is no 'cognito:groups' property, they're not an Admin.
    // If the property exists, but they don't have the right group, they're not an Admin.
    return (
      decodedData["cognito:groups"] &&
      decodedData["cognito:groups"].includes(process.env.REACT_APP_ADMIN_GROUP)
    );
  }
}

export default Auth;
