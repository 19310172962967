import { Button, Modal, TextInput } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../pages/IngredientCategories/IngredientCategories.jsx";
import { createEntity, updateEntity } from "../../utils/Api.utils.js";
import { removeNullValues } from "../../utils/Object.utils.js";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../Notifications/Notifications.jsx";
import isValid from "./IngredientCategoryModal.validation.js";
import styles from "./Modal.module.css";

const IngredientCategoryModal = () => {
  const { opened, open, close, modalInfo, setModalInfo, reload } =
    useContext(ModalContext);

  // Form Data
  const [ingredientCategoryData, setIngredientCategoryData] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  // Update the ingredient category modal to show information when the 'edit' button is clicked on ingredient category rows
  useEffect(() => {
    if (modalInfo) {
      open();
      setIngredientCategoryData(modalInfo);
    }
    // eslint-disable-next-line
  }, [modalInfo]);

  // Actions taken when closing the modal
  const handleClose = () => {
    close();
    setIngredientCategoryData({});
    setModalInfo(null);
    setIsInvalid(false);
  };

  // Adds a different key/value pair to the 'modalInfo' object, without overwritting previous keys/values
  const addToIngredientCategoryData = (keyName, value) => {
    setIngredientCategoryData((prevData) => {
      var data = { ...prevData };
      data[keyName] = value;
      return data;
    });
  };

  // Submits the Ingredient Category entity to be created or updated
  const submitIngredientCategory = async (event) => {
    event.preventDefault();
    const isFormValid = await isValid(ingredientCategoryData);

    if (typeof isFormValid !== "boolean" || !isFormValid) {
      setIsInvalid(isFormValid);
      console.error(
        "There are errors with the ingredient category form data.",
        isFormValid
      );
      return;
    }

    try {
      console.log("Submitting ingredient category.");
      setIsRequesting(true);

      // Remove null values
      removeNullValues(ingredientCategoryData);

      // Send an update OR create request, depending on how the modal is being used
      const entityName = "ingredient-categories";
      const response = modalInfo
        ? updateEntity({
            entityName,
            entityId: ingredientCategoryData.ingredient_category_id,
            body: ingredientCategoryData,
          })
        : createEntity({ entityName, body: ingredientCategoryData });

      response
        .then((data) => {
          setIsRequesting(false);

          if (data.statusCode === 200) {
            handleClose();
            showSuccessNotification(
              `Ingredient Category was ${
                modalInfo ? "updated" : "created"
              } successfully!`
            );
            reload();
          } else {
            showErrorNotification(data.data.message);
          }
        })
        .catch(() => {
          setIsRequesting(false);
          showErrorNotification();
        });
    } catch (error) {
      console.error("An error occurred.", error);
      setIsRequesting(false);
      showErrorNotification();
    }
  };

  return (
    <Modal
      centered
      shadow="md"
      title={`${modalInfo ? "Update" : "Add"} Ingredient Category`}
      opened={opened}
      onClose={handleClose}
    >
      <TextInput
        required
        label="Ingredient Category Name"
        placeholder="Name"
        value={
          ingredientCategoryData && ingredientCategoryData.name
            ? ingredientCategoryData.name
            : ""
        }
        error={isInvalid}
        data-autofocus
        onChange={(event) => {
          setIsInvalid(false);
          addToIngredientCategoryData("name", event.target.value);
        }}
      />
      <Button
        className={styles.mainButton}
        loading={isRequesting}
        onClick={submitIngredientCategory}
      >
        {modalInfo ? "Update" : "Create"}
      </Button>
    </Modal>
  );
};

export default IngredientCategoryModal;
