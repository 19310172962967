import { Flex, Grid, Text } from "@mantine/core";
import {
  CheckCircleOutline,
  Circle,
  MailOutline,
  PhoneOutlined,
} from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { getUnitOfMeasureByAbbreviation } from "../../../datasets/unit-of-measure.js";
import { ModalContext } from "../../../pages/Ingredients/Ingredients.jsx";
import { deleteEntity, updateEntity } from "../../../utils/Api.utils.js";
import { doesArrayHaveData } from "../../../utils/Array.utils.js";
import { formatDate } from "../../../utils/Date.utils.js";
import { ActionsMenu } from "../../ActionsMenu/ActionsMenu.js";
import ImageWithPlaceholder from "../../ImageWithPlaceholder/ImageWithPlaceholder.js";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../Notifications/Notifications.jsx";
import styles from "./IngredientRow.module.css";

const IngredientRow = ({ ingredient }) => {
  const { reload } = useContext(ModalContext);

  // This is used so that, after activating/deactivating a row,
  // the indicator immediately shows/hides without a page refresh.
  const [isActive, setIsActive] = useState(ingredient.is_active);

  /**
   * This acts as an active/inactive toggle for the ingredient
   */
  const handleActiveStatus = () => {
    const entityName = "ingredients";
    updateEntity({
      entityName,
      entityId: ingredient.ingredient_id,
      body: { is_active: !isActive },
    })
      .then((data) => {
        if (data.statusCode === 200) {
          setIsActive((prev) => !prev);
          showSuccessNotification(
            `${ingredient.name} was successfully ${
              data.is_active ? "activated!" : "deactivated!"
            }`
          );
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(
          `Failed to toggle ingredient active status. ${error.message}`
        );
      });
  };

  /**
   * This deletes the ingredient
   */
  const handleDelete = async () => {
    return deleteEntity({
      entityName: "ingredients",
      entityId: ingredient.ingredient_id,
    })
      .then((data) => {
        if (data.statusCode === 200) {
          showSuccessNotification(
            `${ingredient.name} was successfully deleted!`
          );
          reload();
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(`Failed to delete ingredient. ${error.message}`);
      });
  };

  return (
    <tr className={styles.row}>
      <td>
        <SvgIcon
          className={isActive ? styles.active : styles.inactive}
          component={Circle}
        />
      </td>
      <td>
        <Flex gap="sm" align="center">
          <ImageWithPlaceholder
            imageKey={ingredient.image_key}
            avatarText={ingredient.name}
            size={35}
          />
          <Flex direction={"column"}>
            <Text>{ingredient.name}</Text>
            <Text className={styles.info}>
              {ingredient.ingredient_category_name}
            </Text>
          </Flex>
        </Flex>
      </td>
      <td>
        <Flex direction={"column"}>
          {" "}
          <Text className={styles.name}>{ingredient.supplier_name}</Text>
          <Flex>
            <SvgIcon className={styles.icon} component={MailOutline} />
            <Link
              className={styles.info}
              onClick={(event) => {
                event.stopPropagation();
                window.location.href = `mailto:${ingredient.supplier_email}`;
              }}
            >
              {ingredient.supplier_email}
            </Link>
          </Flex>
          <Flex>
            <SvgIcon className={styles.icon} component={PhoneOutlined} />
            <Link
              className={styles.info}
              onClick={(event) => {
                event.stopPropagation();
                window.location.href = `tel:${ingredient.supplier_phone_number}`;
              }}
            >
              {ingredient.supplier_phone_number}
            </Link>
          </Flex>
        </Flex>
      </td>
      <td>
        <Grid gutter="lg" align="flex-start">
          <Grid.Col span={6}>
            <div>
              {ingredient.calories && (
                <Text
                  className={styles.healthInfo}
                >{`${ingredient.calories} Calories`}</Text>
              )}
              {ingredient.sugars && (
                <Text
                  className={styles.healthInfo}
                >{`${ingredient.sugars} Sugars (grams)`}</Text>
              )}
              {ingredient.caffeine && (
                <Text
                  className={styles.healthInfo}
                >{`${ingredient.caffeine} Caffeine (grams)`}</Text>
              )}
            </div>
          </Grid.Col>

          <Grid.Col span={3}>
            <div>
              {ingredient.is_vegan && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SvgIcon
                    className={styles.healthIcon}
                    component={CheckCircleOutline}
                  />
                  <Text>Vegan</Text>
                </div>
              )}
              {ingredient.is_organic && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SvgIcon
                    className={styles.healthIcon}
                    component={CheckCircleOutline}
                  />
                  <Text>Organic</Text>
                </div>
              )}
            </div>
          </Grid.Col>
        </Grid>
      </td>
      <td>
        <Flex direction={"column"}>
          {doesArrayHaveData(ingredient.allergen_names) &&
            ingredient.allergen_names.map((name, index) => {
              return (
                <Text key={index} className={styles.info}>
                  {name}
                </Text>
              );
            })}
        </Flex>
      </td>
      <td>
        <Text>{ingredient.cost_per_unit}</Text>
      </td>
      <td>
        <Text>
          {getUnitOfMeasureByAbbreviation(ingredient.unit_of_measure)}
        </Text>
      </td>
      <td>
        <Text>{formatDate(ingredient.updated_at)}</Text>
      </td>
      <td>
        <ActionsMenu
          modalInfo={ingredient}
          context={ModalContext}
          isActive={isActive}
          handleActiveStatus={handleActiveStatus}
          handleDelete={handleDelete}
        />
      </td>
    </tr>
  );
};

export default IngredientRow;
