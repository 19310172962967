// Formats an ISO 8601 string into a format like so: Oct 16, 2023
const formatDate = (inputDateString) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  const inputDate = new Date(inputDateString);
  return inputDate.toLocaleDateString("en-US", options);
};

// Formats an ISO 8601 string into a format like so: Oct 16, 2023 - 10:51 am
const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();

  // Check if the date is today
  const isToday =
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear();

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Format hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedTime = `${hours % 12 || 12}:${minutes} ${ampm}`;

  // Format date
  if (isToday) {
    return `Today - ${formattedTime}`;
  } else {
    return `${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} - ${formattedTime}`;
  }
};

// Calculates the age of a patient given their date of birth. Returns an object with the years and months.
const calculateAge = (birthDateString) => {
  const birthDate = new Date(birthDateString);
  const today = new Date();

  let ageYears = today.getFullYear() - birthDate.getFullYear();
  let ageMonths = today.getMonth() - birthDate.getMonth();

  if (today.getDate() < birthDate.getDate()) {
    ageMonths--;
  }

  // Adjust years and months
  if (ageMonths < 0) {
    ageYears--;
    ageMonths += 12;
  }

  return { years: ageYears, months: ageMonths };
};

const isDateBetweenDates = (dateToCheck, startDate, endDate) => {
  try {
    if (!(dateToCheck instanceof Date)) {
      dateToCheck = new Date(dateToCheck);
    }

    if (!(startDate instanceof Date)) {
      startDate = new Date(startDate);
    }

    if (!(endDate instanceof Date)) {
      endDate = new Date(endDate);
    }
  } catch (error) {
    console.log(error);
  }

  // Check if the date is between the start and end dates (inclusive)
  return dateToCheck >= startDate && dateToCheck <= endDate;
};

export { calculateAge, formatDate, formatDateTime, isDateBetweenDates };
