export const UNITS_OF_MEASURE = {
  Milliliter: "ml",
  Liter: "l",
  Gram: "g",
  Kilogram: "kg",
  Ounce: "oz",
  Pound: "lb",
  Teaspoon: "tsp",
  Tablespoon: "tbls",
  Cup: "cup",
  Quart: "qt",
  Gallon: "gal",
  Slice: "slice",
};

export function getUnitOfMeasureAbbreviation(unit) {
  return UNITS_OF_MEASURE[unit] || "Unit of measure not found";
}

export function getUnitOfMeasureByAbbreviation(abbreviation) {
  return Object.keys(UNITS_OF_MEASURE).find(
    (unit) => UNITS_OF_MEASURE[unit] === abbreviation
  );
}
