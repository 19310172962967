import { isStringBetweenLength } from "../../utils/String.utils.ts";

const isValid = async (allergenData) => {
  return new Promise((resolve) => {
    const name = allergenData && allergenData.name ? allergenData.name : null;

    if (isStringBetweenLength(name, 1, 100)) {
      resolve(true);
    } else {
      resolve("Allergen name is invalid");
    }
  });
};

export default isValid;
