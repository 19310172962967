import {
  Button,
  Flex,
  Modal,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { STATES, getStateId } from "../../datasets/states.js";
import { ModalContext } from "../../pages/Suppliers/Suppliers.jsx";
import { createEntity, updateEntity } from "../../utils/Api.utils.js";
import { uploadImageWithLambda } from "../../utils/Image.utils.js";
import { removeNullValues } from "../../utils/Object.utils.js";
import { formatPhoneNumber } from "../../utils/PhoneNumber.utils.js";
import { titleCase } from "../../utils/String.utils.ts";
import { ImageUpload } from "../ImageUpload/ImageUpload.jsx";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../Notifications/Notifications.jsx";
import styles from "./Modal.module.css";
import hasFormErrors from "./SupplierModal.validation.js";

const SupplierModal = () => {
  const { opened, open, close, modalInfo, setModalInfo, reload } =
    useContext(ModalContext);

  // Form Data
  const [compressedImage, setCompressedImage] = useState(null);
  const [supplierData, setSupplierData] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  // Update the supplier modal to show information when the 'edit' button is clicked on supplier rows
  useEffect(() => {
    if (modalInfo) {
      open();
      setSupplierData(modalInfo);
    }
    // eslint-disable-next-line
  }, [modalInfo]);

  // Actions taken when closing the modal
  const handleClose = () => {
    close();
    setCompressedImage(null);
    setSupplierData({});
    setModalInfo(null);
    setIsInvalid(false);
  };

  // Adds a different key/value pair to the 'modalInfo' object, without overwritting previous keys/values
  const addToSupplierData = (keyName, value) => {
    setSupplierData((prevData) => {
      var data = { ...prevData };
      data[keyName] = value;
      return data;
    });
  };

  // Submits the Supplier entity to be created or updated
  const submitSupplier = async (event) => {
    event.preventDefault();
    const errorObject = await hasFormErrors(supplierData);

    if (typeof errorObject === "object") {
      setIsInvalid(errorObject);
      console.error(
        "There are errors with the supplier form data.",
        errorObject
      );
      return;
    }

    try {
      console.log("Submitting supplier.");
      setIsRequesting(true);

      // Remove null values
      removeNullValues(supplierData);

      // Send an update OR create request, depending on how the modal is being used
      const entityName = "suppliers";
      const response = modalInfo
        ? updateEntity({
            entityName,
            entityId: supplierData.supplier_id,
            body: supplierData,
          })
        : createEntity({ entityName, body: supplierData });

      response
        .then(async (data) => {
          if (compressedImage) {
            // If the supplier has an image, upload it
            try {
              const imageUploadResponse = await uploadImageWithLambda({
                imageFile: compressedImage,
                entityId: data.data[0].supplier_id,
              });

              if (!supplierData.image_key) {
                await updateEntity({
                  entityName,
                  entityId: data.data[0].supplier_id,
                  body: { image_key: imageUploadResponse.image_key },
                });
              }
            } catch (error) {
              console.error(error.message);
              showErrorNotification(
                `An error occurred while uploading the image.`
              );
            }
          }
          setIsRequesting(false);

          if (data.statusCode === 200) {
            showSuccessNotification(
              `Supplier was ${modalInfo ? "updated" : "created"} successfully!`
            );
          }
        })
        .catch(() => {
          setIsRequesting(false);
          showErrorNotification();
        })
        .finally(() => {
          handleClose();
          reload();
        });
    } catch (error) {
      console.error("An error occurred.", error);
      setIsRequesting(false);
      showErrorNotification();
    }
  };

  return (
    <Modal
      centered
      shadow="md"
      title={`${modalInfo ? "Update" : "Add"} Supplier`}
      opened={opened}
      onClose={handleClose}
    >
      <ImageUpload setCompressedImage={setCompressedImage} />
      <TextInput
        data-autofocus
        required
        className={`${styles.input}`}
        label="Name"
        placeholder="Name"
        error={isInvalid.name}
        value={supplierData && supplierData.name ? supplierData.name : ""}
        onChange={(event) => {
          setIsInvalid(false);
          addToSupplierData("name", event.target.value);
        }}
      />
      <Flex>
        <TextInput
          className={`${styles.halfWidth}`}
          label="Contact First Name"
          placeholder="Contact First Name"
          value={
            supplierData && supplierData.contact_first_name
              ? supplierData.contact_first_name
              : ""
          }
          onChange={(event) => {
            addToSupplierData("contact_first_name", event.target.value);
          }}
        />
        <TextInput
          className={`${styles.halfWidth}`}
          label="Contact Last Name"
          placeholder="Contact Last Name"
          value={
            supplierData && supplierData.contact_last_name
              ? supplierData.contact_last_name
              : ""
          }
          onChange={(event) => {
            addToSupplierData("contact_last_name", event.target.value);
          }}
        />
      </Flex>
      <Flex>
        <TextInput
          className={`${styles.halfWidth}`}
          label="Address Line 1"
          placeholder="Address Line 1"
          value={
            supplierData && supplierData.address_1 ? supplierData.address_1 : ""
          }
          onChange={(event) => {
            addToSupplierData("address_1", event.target.value);
          }}
        />
        <TextInput
          className={`${styles.halfWidth}`}
          label="Address Line 2"
          placeholder="Address Line 2"
          value={
            supplierData && supplierData.address_2 ? supplierData.address_2 : ""
          }
          onChange={(event) => {
            addToSupplierData("address_2", event.target.value);
          }}
        />
      </Flex>
      <Flex>
        <TextInput
          className={`${styles.thirdWidth}`}
          label="City"
          placeholder="City"
          value={supplierData && supplierData.city ? supplierData.city : ""}
          onChange={(event) => {
            addToSupplierData("city", event.target.value);
          }}
        />
        <Select
          className={`${styles.thirdWidth}`}
          checkIconPosition="right"
          label="State"
          placeholder="State"
          clearable
          searchable
          data={Object.keys(STATES)}
          value={
            supplierData && supplierData.state_name
              ? titleCase(supplierData.state_name)
              : ""
          }
          nothingFoundMessage="No state found..."
          onChange={(value) => {
            addToSupplierData("state_name", value);
            addToSupplierData("state_id", getStateId(value));
          }}
        />
        <TextInput
          className={`${styles.thirdWidth}`}
          label="Postal Code"
          placeholder="Postal Code"
          value={
            supplierData && supplierData.postal_code
              ? supplierData.postal_code
              : ""
          }
          onChange={(event) => {
            addToSupplierData("postal_code", event.target.value);
          }}
        />
      </Flex>
      <Flex>
        <TextInput
          className={`${styles.input} ${styles.halfWidth}`}
          label="Email"
          placeholder="Email"
          value={supplierData && supplierData.email ? supplierData.email : ""}
          onChange={(event) => {
            addToSupplierData("email", event.target.value.trim().toLowerCase());
          }}
        />
        <TextInput
          className={`${styles.input} ${styles.halfWidth}`}
          label="Phone Number"
          placeholder="Phone Number"
          value={
            supplierData && supplierData.phone_number
              ? supplierData.phone_number
              : ""
          }
          onChange={(event) => {
            const formattedNumber = formatPhoneNumber(
              event.target.value.trim()
            );
            addToSupplierData("phone_number", formattedNumber);
          }}
        />
      </Flex>
      <TextInput
        className={`${styles.input}`}
        label="Website"
        placeholder="Website"
        value={supplierData && supplierData.website ? supplierData.website : ""}
        onChange={(event) => {
          addToSupplierData("website", event.target.value);
        }}
      />
      <Textarea
        className={`${styles.input}`}
        label="Notes"
        placeholder="Notes"
        value={supplierData && supplierData.notes ? supplierData.notes : ""}
        onChange={(event) => {
          addToSupplierData("notes", event.target.value);
        }}
      />
      <Button
        className={styles.mainButton}
        loading={isRequesting}
        onClick={submitSupplier}
      >
        {modalInfo ? "Update" : "Create"}
      </Button>
    </Modal>
  );
};

export default SupplierModal;
