import { showErrorNotification } from "../../components/Notifications/Notifications";

const emailRegex = /\S+@\S+\.\S+/;
const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const tooLongError =
  "The email or password is too long. Let's be reasonable here...";
const noDataError = "Please provide a username and password.";
const newPasswordError = "Please provide a new password.";
const noMatchError = "Passwords do not match";
const emailRegexError = "Please enter a valid email";
const passwordRegexError =
  "New password must be 8 characters long and contain an uppercase letter, lower case letter, a number, and a special character";

const loginValidation = async (loginData, newPasswordRequired) => {
  return new Promise((resolve) => {
    if (loginData) {
      let isValid = true;

      if (newPasswordRequired) {
        if (
          !loginData.email ||
          !loginData.newPassword ||
          !loginData.confirmPassword
        ) {
          showErrorNotification(newPasswordError);
          isValid = false;
        } else if (loginData.newPassword !== loginData.confirmPassword) {
          showErrorNotification(noMatchError);
          isValid = false;
        } else if (!passwordRegex.test(loginData.newPassword)) {
          showErrorNotification(passwordRegexError);
          isValid = false;
        }
      } else {
        if (!loginData.email || !loginData.password) {
          showErrorNotification(noDataError);
          isValid = false;
        } else if (
          loginData.email.length > 50 ||
          loginData.password.length > 50
        ) {
          showErrorNotification(tooLongError);
          isValid = false;
        } else if (!emailRegex.test(loginData.email)) {
          showErrorNotification(emailRegexError);
          isValid = false;
        }
      }

      // Other validations go here...

      resolve(isValid);
    } else {
      resolve(false);
    }
  });
};

export default loginValidation;
