import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { Navigate } from "react-router-dom";
import Auth from "./Auth/Auth";
import { setLocalStorage } from "./LocalStorage.utils.js";

const auth = new Auth();

/**
 * Checks if it is expired.
 *
 * @param {*} token The token to be checked for expiration.
 * @returns true if the token is expired.
 */
export const isTokenExpired = (token) => {
  const decodedToken = jwt_decode(token);
  return dayjs.unix(decodedToken.exp).diff(dayjs()) < 1;
};

/**
 * Creates a new entity
 */
const createEntity = async ({ entityName, body }) => {
  return new Promise((resolve, reject) => {
    const requestUrl = `/${entityName}`;
    makeApiRequest("POST", requestUrl, body)
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * Updates an existing entity
 */
const updateEntity = async ({ entityName, entityId, body }) => {
  return new Promise((resolve, reject) => {
    const requestUrl = `/${entityName}/${entityId}`;
    makeApiRequest("PUT", requestUrl, body)
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * Deletes an existing entity
 */
const deleteEntity = async ({ entityName, entityId }) => {
  return new Promise((resolve, reject) => {
    const requestUrl = `/${entityName}/${entityId}`;
    makeApiRequest("DELETE", requestUrl)
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * Gets a list of entities
 */
const getEntities = async ({ entityName, search, offset, limit, isActive }) => {
  return new Promise((resolve, reject) => {
    var params = [];

    if (search !== null && typeof search === "string" && search.length > 0) {
      params.push(`search=${search}`);
    }

    if (offset !== null && typeof offset === "number") {
      params.push(`offset=${offset}`);
    }

    if (limit !== null && typeof limit === "number") {
      params.push(`limit=${limit}`);
    }

    if (isActive !== null && typeof isActive === "boolean") {
      params.push(`is_active=${isActive}`);
    }

    const queryParams = params.length > 0 ? `?${params.join("&")}` : "";
    const requestUrl = `/${entityName}${queryParams}`;

    makeApiRequest("GET", requestUrl)
      .then((data) => {
        console.log(data);
        resolve(data && data.data ? data.data : { data: [] });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * Gets the count for the entities name provided.
 * Note: EntitiesName should be plural (insurances, ingredients, etc...)
 */
const getEntitiesCount = async ({ entitiesName, search, isActive }) => {
  return new Promise((resolve, reject) => {
    var params = [];

    if (search !== null && typeof search === "string" && search.length > 0) {
      params.push(`search=${search}`);
    }

    if (isActive !== null && typeof isActive === "boolean") {
      params.push(`is_active=${isActive}`);
    }

    const queryParams = params.length > 0 ? `?${params.join("&")}` : "";
    const requestUrl = `/${entitiesName}/count${queryParams}`;

    makeApiRequest("GET", requestUrl)
      .then((data) => {
        resolve(data && data.data ? data.data : { data: { count: 0 } });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * Checks the validity of the provided NPI Number
 */
const isNpiNumberValid = async ({ npi }) => {
  return new Promise((resolve, reject) => {
    const requestUrl = `/npi/${npi}`;
    makeApiRequest("GET", requestUrl)
      .then((data) => {
        const isValid =
          data &&
          data.data &&
          "result_count" in data.data &&
          data.data.result_count > 0;
        resolve(isValid);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

// Gets a list of the provided entity
const getEntitiesForList = async ({ path }) => {
  return new Promise((resolve, reject) => {
    makeApiRequest("GET", `${path}/list`)
      .then((data) => {
        resolve(data && data.data ? data.data : { data: [] });
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * Makes an API request depending on the arguments passed in.
 *
 * @param {*} method "POST", "GET", "PUT", or "DELETE"
 * @param {*} path The path to make the request to.
 * @param {*} body The body of the request. Used for POST and PUT requests.
 */
const makeApiRequest = async (method, path, body) => {
  let encodedIdToken;
  try {
    encodedIdToken = await auth.getSessionToken("idToken");

    if (isTokenExpired(encodedIdToken) || encodedIdToken === null) {
      console.log("Token is expired. Refreshing tokens...");
      const refreshResponse = await auth.retrieveNewTokens();

      if (!refreshResponse || refreshResponse === "null") {
        <Navigate to="/login" />;
      } else {
        // This token is used for the request that is about to be made.
        // If it was expired, then successfully refreshed, we should assign the new token to this variable.
        encodedIdToken = await auth.getSessionToken("idToken");
      }
    }
  } catch (error) {
    // If retrieving a new token fails, that's because the refresh token is expired. Navigate user to login page to retrieve a new refresh token.
    setLocalStorage("sessionData", null);
    <Navigate to="/login" />;
  }

  // If "path" has https:// or http:// in it, then it's a full URL and we should use it as-is.
  // Otherwise, prepend the base URL to it.
  let requestUrl;
  if (!path.includes("http://") && !path.includes("https://")) {
    requestUrl = process.env.REACT_APP_BASE_URL + path;
  } else {
    requestUrl = path;
  }
  console.log(requestUrl);

  var headers = new Headers();
  headers.append("Authorization", encodedIdToken);
  headers.append("Accept", "application/json");
  headers.append("Content-Type", "application/json");

  return fetch(requestUrl, {
    method: method,
    headers: headers,
    redirect: "follow",
    body: JSON.stringify(body),
  })
    .then((response) => {
      return Promise.all([response.status, response.json()]);
    })
    .then(([statusCode, data]) => {
      console.log(`Request to ${path} has status: ${statusCode}.`);
      return { statusCode: statusCode, data: data };
    })
    .catch((error) => {
      console.error(error);
      return { statusCode: 500, data: error.message };
    });
};

export {
  createEntity,
  deleteEntity,
  getEntities,
  getEntitiesCount,
  getEntitiesForList,
  isNpiNumberValid,
  makeApiRequest,
  updateEntity,
};
