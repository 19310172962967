import {
  Button,
  Checkbox,
  Flex,
  Loader,
  Modal,
  MultiSelect,
  NumberInput,
  Select,
  TextInput,
} from "@mantine/core";
import { AttachMoney } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  UNITS_OF_MEASURE,
  getUnitOfMeasureAbbreviation,
  getUnitOfMeasureByAbbreviation,
} from "../../datasets/unit-of-measure.js";
import { ModalContext } from "../../pages/Ingredients/Ingredients.jsx";
import {
  createEntity,
  getEntitiesForList,
  updateEntity,
} from "../../utils/Api.utils.js";
import { doesArrayHaveData } from "../../utils/Array.utils.js";
import { uploadImageWithLambda } from "../../utils/Image.utils.js";
import { removeNullValues } from "../../utils/Object.utils.js";
import { ImageUpload } from "../ImageUpload/ImageUpload.jsx";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../Notifications/Notifications.jsx";
import hasFormErrors from "./IngredientModal.validation.js";
import styles from "./Modal.module.css";

const IngredientModal = () => {
  const { opened, open, close, modalInfo, setModalInfo, reload } =
    useContext(ModalContext);

  // Form Data
  const [compressedImage, setCompressedImage] = useState(null);
  const [ingredientData, setIngredientData] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const [isLoadingAllergens, setIsLoadingAllergens] = useState(false);
  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(false);
  const [isLoadingIngredientCategories, setIsLoadingIngredientCategories] =
    useState(false);

  const [allergens, setAllergens] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [ingredientCategories, setIngredientCategories] = useState([]);

  // Update the ingredient modal to show information when the 'edit' button is clicked on ingredient rows
  useEffect(() => {
    if (modalInfo) {
      open();
      setIngredientData(modalInfo);
    } else if (!opened) {
      return; // Do nothing if the modal is not open
    }

    loadAllergens();
    loadSuppliers();
    loadIngredientCategories();

    // eslint-disable-next-line
  }, [modalInfo, opened]);

  // Requests a list of allergens
  const loadAllergens = () => {
    setIsLoadingAllergens(true);
    getEntitiesForList({
      path: `/allergens`,
    }).then((data) => {
      if (doesArrayHaveData(data)) {
        const allergenList = data.map((allergen) => {
          return {
            value: allergen.allergen_id,
            label: allergen.name,
          };
        });
        setAllergens(allergenList);
      }
      setIsLoadingAllergens(false);
    });
  };

  // Requests a list of suppliers
  const loadSuppliers = () => {
    setIsLoadingSuppliers(true);
    getEntitiesForList({
      path: `/suppliers`,
    }).then((data) => {
      if (doesArrayHaveData(data)) {
        const supplierList = data.map((supplier) => {
          return {
            value: supplier.supplier_id,
            label: supplier.name,
          };
        });
        setSuppliers(supplierList);
      }
      setIsLoadingSuppliers(false);
    });
  };

  // Requests a list of ingredient categories
  const loadIngredientCategories = () => {
    setIsLoadingIngredientCategories(true);
    getEntitiesForList({
      path: `/ingredient-categories`,
    }).then((data) => {
      if (doesArrayHaveData(data)) {
        const ingredientCategoryList = data.map((category) => {
          return {
            value: category.ingredient_category_id,
            label: category.name,
          };
        });
        setIngredientCategories(ingredientCategoryList);
      }
      setIsLoadingIngredientCategories(false);
    });
  };

  // Actions taken when closing the modal
  const handleClose = () => {
    close();
    setCompressedImage(null);
    setIngredientData({});
    setModalInfo(null);
    setIsInvalid(false);
  };

  // Adds a different key/value pair to the 'modalInfo' object, without overwritting previous keys/values
  const addToIngredientData = (keyName, value) => {
    setIngredientData((prevData) => {
      var data = { ...prevData };
      data[keyName] = value;
      return data;
    });
  };

  // Submits the Ingredient entity to be created or updated
  const submitIngredient = async (event) => {
    event.preventDefault();

    const errorObject = await hasFormErrors(ingredientData);

    if (typeof errorObject === "object") {
      setIsInvalid(errorObject);
      console.error(
        "There are errors with the ingredient form data.",
        errorObject
      );
      return;
    }

    try {
      console.log("Submitting ingredient.");
      setIsRequesting(true);

      // Remove null values
      removeNullValues(ingredientData);

      // Send an update OR create request, depending on how the modal is being used
      const entityName = "ingredients";
      const response = modalInfo
        ? updateEntity({
            entityName,
            entityId: ingredientData.ingredient_id,
            body: ingredientData,
          })
        : createEntity({ entityName, body: ingredientData });

      response
        .then(async (data) => {
          // If the ingredient has an image, upload it
          if (compressedImage) {
            try {
              const imageUploadResponse = await uploadImageWithLambda({
                imageFile: compressedImage,
                entityId: data.data[0].ingredient_id,
              });

              if (!ingredientData.image_key) {
                await updateEntity({
                  entityName,
                  entityId: data.data[0].ingredient_id,
                  body: { image_key: imageUploadResponse.image_key },
                });
              }
            } catch (error) {
              console.error(error.message);
              showErrorNotification(
                `An error occurred while uploading the image.`
              );
            }
          }
          setIsRequesting(false);

          if (data.statusCode === 200) {
            showSuccessNotification(
              `Ingredient was ${
                modalInfo ? "updated" : "created"
              } successfully!`
            );
          }
        })
        .catch(() => {
          setIsRequesting(false);
          showErrorNotification();
        })
        .finally(() => {
          handleClose();
          reload();
        });
    } catch (error) {
      console.error("An error occurred.", error);
      setIsRequesting(false);
      showErrorNotification();
    }
  };

  return (
    <Modal
      centered
      shadow="md"
      title={`${modalInfo ? "Update" : "Add"} Ingredient`}
      opened={opened}
      onClose={handleClose}
    >
      <ImageUpload setCompressedImage={setCompressedImage} />
      <TextInput
        data-autofocus
        required
        className={styles.input}
        label="Name"
        placeholder="Name"
        error={isInvalid.name}
        value={ingredientData && ingredientData.name ? ingredientData.name : ""}
        onChange={(event) => {
          setIsInvalid(false);
          addToIngredientData("name", event.target.value);
        }}
      />
      <Flex>
        <Select
          className={styles.halfWidth}
          label="Category"
          placeholder="Category"
          data={ingredientCategories}
          rightSection={
            isLoadingIngredientCategories ? <Loader size={16} /> : null
          }
          value={
            ingredientData && ingredientData.ingredient_category_id
              ? ingredientData.ingredient_category_id
              : ""
          }
          onChange={(value) => {
            setIsInvalid(false);
            addToIngredientData("ingredient_category_id", value);
          }}
        />
        <Select
          className={styles.halfWidth}
          label="Supplier"
          placeholder="Supplier"
          data={suppliers}
          rightSection={isLoadingSuppliers ? <Loader size={16} /> : null}
          value={
            ingredientData && ingredientData.supplier_id
              ? ingredientData.supplier_id
              : ""
          }
          onChange={(value) => {
            setIsInvalid(false);
            addToIngredientData("supplier_id", value);
          }}
        />
      </Flex>
      <Flex>
        <NumberInput
          className={styles.thirdWidth}
          label="Calories"
          placeholder="Calories"
          hideControls
          allowNegative={false}
          allowDecimal={true}
          decimalScale={2}
          value={
            ingredientData && ingredientData.calories
              ? ingredientData.calories
              : ""
          }
          onChange={(value) => {
            addToIngredientData("calories", value.toString());
          }}
        />
        <NumberInput
          className={styles.thirdWidth}
          label="Sugars"
          placeholder="Sugars"
          hideControls
          allowNegative={false}
          allowDecimal={true}
          decimalScale={2}
          value={
            ingredientData && ingredientData.sugars ? ingredientData.sugars : ""
          }
          onChange={(value) => {
            addToIngredientData("sugars", value.toString());
          }}
        />
        <NumberInput
          className={styles.thirdWidth}
          label="Caffeine"
          placeholder="Caffeine"
          hideControls
          allowNegative={false}
          allowDecimal={true}
          decimalScale={2}
          value={
            ingredientData && ingredientData.caffeine
              ? ingredientData.caffeine
              : ""
          }
          onChange={(value) => {
            addToIngredientData("caffeine", value.toString());
          }}
        />
      </Flex>
      <Flex>
        <NumberInput
          className={styles.halfWidth}
          label="Cost Per Unit"
          placeholder="Cost Per Unit"
          leftSection={<SvgIcon component={AttachMoney} />}
          hideControls
          allowNegative={false}
          allowDecimal={true}
          decimalScale={2}
          fixedDecimalScale
          value={
            ingredientData && ingredientData.cost_per_unit
              ? ingredientData.cost_per_unit
              : ""
          }
          onChange={(value) => {
            addToIngredientData("cost_per_unit", value.toString());
          }}
        />
        <Select
          className={styles.halfWidth}
          label="Unit Of Measure"
          placeholder="Unit Of Measure"
          data={Object.keys(UNITS_OF_MEASURE)}
          value={
            ingredientData && ingredientData.unit_of_measure
              ? getUnitOfMeasureByAbbreviation(ingredientData.unit_of_measure)
              : "Ounce"
          }
          onChange={(value) => {
            addToIngredientData(
              "unit_of_measure",
              getUnitOfMeasureAbbreviation(value)
            );
          }}
        />
      </Flex>
      <MultiSelect
        className={styles.input}
        classNames={{ pill: styles.pill }}
        label="Allergens"
        placeholder="Allergens"
        clearable
        searchable
        checkIconPosition="right"
        rightSection={isLoadingAllergens ? <Loader size={16} /> : null}
        data={allergens}
        value={
          ingredientData && ingredientData.allergen_ids
            ? ingredientData.allergen_ids
            : []
        }
        onChange={(values) => {
          addToIngredientData("allergen_ids", values);
        }}
      />
      <Flex>
        <Checkbox
          className={styles.halfWidth}
          label="Is Organic?"
          checked={
            ingredientData && ingredientData.is_organic
              ? ingredientData.is_organic
              : null
          }
          onChange={(event) => {
            addToIngredientData(
              "is_organic",
              Boolean(event.currentTarget.checked)
            );
          }}
        />
        <Checkbox
          className={styles.halfWidth}
          label="Is Vegan?"
          checked={
            ingredientData && ingredientData.is_vegan
              ? ingredientData.is_vegan
              : null
          }
          onChange={(event) => {
            addToIngredientData(
              "is_vegan",
              Boolean(event.currentTarget.checked)
            );
          }}
        />
      </Flex>
      <Button
        className={styles.mainButton}
        loading={isRequesting}
        onClick={submitIngredient}
      >
        {modalInfo ? "Update" : "Create"}
      </Button>
    </Modal>
  );
};

export default IngredientModal;
