const presetColors = [
  "red",
  "blue",
  "green",
  "orange",
  "purple",
  "aqua",
  "aquamarine",
  "blueviolet",
  "cadetblue",
  "coral",
  "cornflowerblue",
  "cyan",
  "crimson",
  "darkviolet",
  "deeppink",
  "maroon",
  "fuchsia",
  "lime",
  "yellow",
  "teal",
  "darkgreen",
  "darkmagenta",
  "lightgreen",
];

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * presetColors.length);
  return presetColors[randomIndex];
};

export { getRandomColor };
