import { isStringBetweenLength } from "../../utils/String.utils.ts";

const hasFormErrors = async (supplierData) => {
  return new Promise((resolve) => {
    const errorObject = {
      name: "Please enter a supplier name",
    };

    if (
      supplierData &&
      typeof supplierData === "object" &&
      Object.keys(supplierData).length > 0
    ) {
      // Validate the supplier name
      const name = supplierData.name;
      if (isStringBetweenLength(name, 1, 100)) {
        errorObject.name = false;
      }

      // If all values in the errorObject are false, that means they passed
      // validation, which means we have no errors.
      const allFalse = Object.values(errorObject).every(
        (value) => value === false
      );
      resolve(allFalse ? false : errorObject);
    } else {
      resolve(errorObject);
    }
  });
};

export default hasFormErrors;
