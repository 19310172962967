import { Button, Group, Title } from "@mantine/core";
import { ControlPoint } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useContext } from "react";
import styles from "./PageTitle.module.css";

const PageTitle = ({ title, buttonLabel, context }) => {
  const { open } = useContext(context);

  return (
    <Group justify="space-between" className={styles.header}>
      <Title order={1} className={styles.title}>
        {title}
      </Title>
      <Button
        className={styles.button}
        leftSection={<SvgIcon component={ControlPoint} />}
        onClick={open}
      >
        {buttonLabel}
      </Button>
    </Group>
  );
};

export default PageTitle;
