import { Avatar } from "@mantine/core";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getRandomColor } from "../../utils/Color.utils.js";
import { createAcronym } from "../../utils/String.utils.ts";
import styles from "./ImageWithPlaceholder.module.css";

const ImageWithPlaceholder = ({ imageKey, avatarText, size = 50 }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [color] = useState(getRandomColor());

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setLoadError(true);
  };

  const renderPlaceholder = () => {
    return avatarText ? (
      <Avatar variant="filled" size={size} color={color}>
        {createAcronym(avatarText, 2)}
      </Avatar>
    ) : (
      <Avatar src={""} alt={""} radius="xl" size={size} />
    );
  };

  return (
    <>
      {(!imageLoaded || loadError) && renderPlaceholder()}
      <LazyLoadImage
        className={
          imageLoaded && !loadError ? styles.lazyLoadedImage : styles.noImage
        }
        src={
          imageKey ? `${process.env.REACT_APP_GET_IMAGE_URL}/${imageKey}` : null
        }
        alt={"image"}
        onLoad={handleImageLoad}
        onError={handleImageError}
        height={size}
        width={size}
        threshold={100}
        visibleByDefault={imageKey ? false : true}
      />
    </>
  );
};

export default ImageWithPlaceholder;
