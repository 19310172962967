import {
  Button,
  Card,
  Group,
  Image,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { AlternateEmail, Lock } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoFull from "../../assets/chug-logo-blue.png";
import Auth from "../../utils/Auth/Auth";
import styles from "./ForgotPassword.module.css";
import {
  emailValidation,
  forgotPasswordValidation,
} from "./ForgotPassword.validation";

const auth = new Auth();

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [forgotPasswordPhase, setForgotPasswordPhase] = useState(0);
  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const onSubmit = async (event) => {
    event.preventDefault();

    // Validate the form data
    const isFormValid = !forgotPasswordPhase
      ? await emailValidation(email)
      : await forgotPasswordValidation(code, newPassword, confirmPassword);

    // If there are any validation errors on the form, don't try to execute the 'forgot password' flow
    if (!isFormValid) {
      console.error("There are errors with the forgot password form data.");
      return;
    }

    // Do the 'send code' or 'change password flow' here
    if (!forgotPasswordPhase) {
      auth.sendVerificationCode(email, setForgotPasswordPhase);
    } else {
      auth.resetPassword(email, code, newPassword).then((didReset) => {
        if (didReset) {
          navigate("/login");
        }
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSubmit(e);
    }
  };

  return (
    <div>
      <Group
        className={styles.forgotPasswordContentContainer}
        justify={"center"}
      >
        <Card className={styles.forgotPasswordCard}>
          <Image className={styles.logo} src={logoFull} />
          <Text className={styles.text}>Forgot Password</Text>
          <form onKeyDown={handleKeyDown} className={styles.form}>
            {!forgotPasswordPhase ? (
              <TextInput
                size="lg"
                radius="md"
                leftSection={<SvgIcon component={AlternateEmail} />}
                placeholder="Email Address"
                className={styles.input}
                data-cy="forgot-password-email-input"
                autoFocus
                onChange={(event) => setEmail(event.target.value)}
              />
            ) : (
              <>
                <TextInput
                  size="lg"
                  radius="md"
                  className={styles.input}
                  leftSection={<SvgIcon component={Lock} />}
                  placeholder="Code"
                  data-cy="forgot-password-code-input"
                  onChange={(event) => {
                    setCode(event.target.value);
                  }}
                />
                <PasswordInput
                  size="lg"
                  radius="md"
                  leftSection={<SvgIcon component={Lock} />}
                  placeholder="New Password"
                  className={styles.input}
                  data-cy="forgot-password-new-password-input"
                  onChange={(event) => {
                    setNewPassword(event.target.value);
                  }}
                />
                <PasswordInput
                  size="lg"
                  radius="md"
                  leftSection={<SvgIcon component={Lock} />}
                  placeholder="Confirm Password"
                  className={styles.input}
                  data-cy="forgot-password-confirm-password-input"
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                  }}
                />
              </>
            )}
            <Button
              size="sm"
              variant="filled"
              className={styles.button}
              onClick={onSubmit}
              component={Link}
              to="/login"
              data-cy="forgot-password-submit-button"
            >
              {forgotPasswordPhase ? "Change Password" : "Send Code"}
            </Button>
            {forgotPasswordPhase ? (
              <Text
                className={styles.passwordRequirementsText}
                data-cy="forgot-password-password-requirements-text"
              >
                Password must be at least 8 characters in length and contain a
                number, a capital letter, a lower case letter, and a special
                character.
              </Text>
            ) : null}
          </form>
          <Link
            to={"/login"}
            className={styles.link}
            data-cy="forgot-password-back-to-login"
          >
            Back to Login
          </Link>
        </Card>
      </Group>
    </div>
  );
};

export default ForgotPassword;
