import { ActionIcon, Menu } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  AddCircleOutlineOutlined,
  DeleteOutline,
  EditOutlined,
  MoreVert,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useContext, useState } from "react";
import DeleteConfirmation from "../Modals/DeleteConfirmation";
import styles from "./ActionMenu.module.css";

export const ActionsMenu = ({
  modalInfo,
  context,
  isActive,
  handleActiveStatus,
  handleDelete,
}) => {
  // Delete Confirmation Modal State
  const [opened, { open, close }] = useDisclosure(false);
  const [isLoading, setIsLoading] = useState(false);

  // Context from the various rows that use this actions menu
  const { setModalInfo } = useContext(context);

  const deleteWithLoading = async () => {
    setIsLoading(true);
    await handleDelete();
    setIsLoading(false);
  };

  return (
    <>
      <DeleteConfirmation
        opened={opened}
        close={close}
        handleDelete={deleteWithLoading}
        isLoading={isLoading}
      />
      <Menu closeOnClickOutside position="bottom-end">
        <Menu.Target>
          <ActionIcon
            color="black"
            variant="subtle"
          >
            <SvgIcon component={MoreVert} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<SvgIcon component={EditOutlined} />}
            className={styles.menuItem}
            onClick={() => {
              setModalInfo(modalInfo);
            }}
          >
            Edit
          </Menu.Item>
          {handleActiveStatus && (
            <Menu.Item
              leftSection={
                <SvgIcon
                  component={
                    isActive ? RemoveCircleOutline : AddCircleOutlineOutlined
                  }
                />
              }
              className={styles.menuItem}
              onClick={handleActiveStatus}
            >
              {isActive ? "Deactivate" : "Activate"}
            </Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item
            leftSection={<SvgIcon component={DeleteOutline} />}
            className={styles.deleteItem}
            onClick={open}
          >
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};
