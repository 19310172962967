import { Pagination, Table, Text } from "@mantine/core";
import { useContext } from "react";
import { SupplierContext } from "../../../pages/Suppliers/Suppliers.jsx";
import { doesArrayHaveData } from "../../../utils/Array.utils.js";
import LoadingRows from "../../Loading/LoadingRows.jsx";
import { NoRowsFound } from "../../Rows/NoRowsFound/NoRowsFound.jsx";
import SupplierRow from "../../Rows/Suppliers/SupplierRow.jsx";
import styles from "./SupplierTable.module.css";

const SupplierTable = ({ isLoading, total, activePage, setActivePage }) => {
  const suppliers = useContext(SupplierContext);

  /**
   * Create the table header
   */
  const tableHead = (
    <tr>
      <th className={styles.indicatorHeader} />
      <th>
        <Text className={`${styles.headerText}`}>Supplier</Text>
      </th>
      <th>
        <Text className={`${styles.headerText}`}>Contact</Text>
      </th>
      <th>
        <Text className={`${styles.headerText}`}>Address</Text>
      </th>
      <th>
        <Text className={`${styles.headerText}`}>Contact Info</Text>
      </th>
      <th>
        <Text className={`${styles.headerText}`}>Created</Text>
      </th>
      <th className={styles.menuHeader} />
    </tr>
  );

  /**
   * Create the table rows
   */
  const tableRows = isLoading ? (
    <LoadingRows numColumns={6} numRows={20} hidden={[0]} />
  ) : doesArrayHaveData(suppliers) ? (
    suppliers.map((supplier) => {
      return <SupplierRow key={supplier.supplier_id} supplier={supplier} />;
    })
  ) : (
    <NoRowsFound numberOfColumns={6} />
  );

  // We call this 'tableFoot', but we're not putting it in the table's footer.
  // This is because the footer is still constrained by the column widths like the rest of the table.
  // Instead, we're putting it in the table caption, because we no longer have those width constraints
  // and we can still make the caption look like a footer.
  const tableFoot = (
    <div className={styles.paginationContainer}>
      <Pagination
        classNames={{ control: styles.activePage }}
        size="sm"
        page={activePage}
        boundaries={2}
        total={total}
        onChange={(value) => {
          setActivePage(value);
          document.getElementById("scrollableContainer").scrollTo(0, 0);
        }}
      />
    </div>
  );

  return (
    <div className={styles.scrollableContainer} id="scrollableContainer">
      <Table captionSide="bottom" className={styles.table}>
        <thead className={styles.head}>{tableHead}</thead>
        <tbody className={styles.body}>{tableRows}</tbody>
        <caption className={styles.caption}>{tableFoot}</caption>
      </Table>
    </div>
  );
};

export default SupplierTable;
