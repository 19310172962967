import { notifications } from "@mantine/notifications";
import { SvgIcon } from "@mui/material";
import { CancelOutlined, CheckCircleOutline, InfoOutlined } from "@mui/icons-material";

const autoCloseTime = 3000;

const showSuccessNotification = (message) => {
  notifications.show({
    message: message,
    color: "green", // For some reason, we can't pass colors from the 'colors' file into a notification
    autoClose: autoCloseTime,
    icon: <SvgIcon component={CheckCircleOutline} />,
  });
};

const showInfoNotification = (message) => {
  notifications.show({
    message: message,
    color: "blue", // For some reason, we can't pass colors from the 'colors' file into a notification
    autoClose: autoCloseTime,
    icon: <SvgIcon component={InfoOutlined} />,
  });
};

const showErrorNotification = (message) => {
  message = message ? message : "An error occurred. Please try again...";

  notifications.show({
    message: message,
    color: "red", // For some reason, we can't pass colors from the 'colors' file into a notification
    autoClose: autoCloseTime,
    icon: <SvgIcon component={CancelOutlined} />,
  });
};

export { showSuccessNotification, showInfoNotification, showErrorNotification };
