import {
  Button,
  Card,
  Group,
  Image,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";
import { AlternateEmail, Lock } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoFull from "../../assets/chug-logo-blue.png";
import { showErrorNotification } from "../../components/Notifications/Notifications";
import Auth from "../../utils/Auth/Auth";
import styles from "./Login.module.css";
import loginValidation from "./Login.validation";

const auth = new Auth();

const Login = () => {
  const navigate = useNavigate();
  const emailRef = useRef();
  const [password, setPassword] = useState(""); // We need to use state for this variable so we can persist the password, even after the field disappears (when a new password is required).
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const onSubmit = async (event) => {
    setIsLoggingIn(true);
    event.preventDefault();

    // Check to make sure ref values are defined, if not, assign null
    const email = emailRef.current ? emailRef.current.value.trim() : null;
    const newPassword = newPasswordRef.current
      ? newPasswordRef.current.value.trim()
      : null;
    const confirmPassword = confirmPasswordRef.current
      ? confirmPasswordRef.current.value.trim()
      : null;

    // Validate the form data
    const isFormValid = await loginValidation(
      {
        email,
        password,
        newPassword,
        confirmPassword,
      },
      newPasswordRequired
    );

    // If there are any validation errors on the form, don't try to login
    if (!isFormValid) {
      console.error("There are errors with the login form data.");
      setIsLoggingIn(false);
      return;
    }

    auth
      .authenticate(email, password, newPassword)
      .then((data) => {
        console.log(data);
        setNewPasswordRequired(data.new_password_required);

        // If no new password is required, go to the app.
        if (!data.new_password_required) {
          // If they're not an Admin, prevent password reset
          if (!auth.isAdminUser(data)) {
            auth.logout();
            navigate("/login");
            showErrorNotification("This user is not a Chug Admin.");
            return;
          }
          navigate("/drinks");
        }
      })
      .catch((error) => {
        showErrorNotification(error.message);
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };

  // When the user presses 'Enter' on the login form, we actually try to login.
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSubmit(e);
    }
  };

  return (
    <div>
      <Group className={styles.loginContentContainer} justify={"center"}>
        <Card className={styles.loginCard}>
          <Image className={styles.logo} src={logoFull} />
          <Text className={styles.text}>Sign In</Text>
          <form onKeyDown={handleKeyDown}>
            <TextInput
              size="lg"
              radius="md"
              leftSection={<SvgIcon component={AlternateEmail} />}
              placeholder="Email Address"
              className={styles.input}
              autoFocus
              ref={emailRef}
              data-cy="login-email-input"
            />
            {/* If a new password is NOT required, show the password field.
                If it is required, show the New and Confirm password fields. */}
            {!newPasswordRequired ? (
              <PasswordInput
                size="lg"
                radius="md"
                leftSection={<SvgIcon component={Lock} />}
                placeholder="Password"
                className={styles.input}
                onChange={(event) => setPassword(event.target.value)}
                data-cy="login-password-input"
              />
            ) : null}

            {newPasswordRequired ? (
              <>
                <PasswordInput
                  size="lg"
                  radius="md"
                  leftSection={<SvgIcon component={Lock} />}
                  placeholder="New Password"
                  className={styles.input}
                  ref={newPasswordRef}
                  data-cy="login-new-password-input"
                />
                <PasswordInput
                  size="lg"
                  radius="md"
                  leftSection={<SvgIcon component={Lock} />}
                  placeholder="Confirm Password"
                  className={styles.input}
                  ref={confirmPasswordRef}
                  data-cy="login-confirm-password-input"
                />
              </>
            ) : null}

            <Button
              size="sm"
              variant="filled"
              className={styles.button}
              onClick={onSubmit}
              loading={isLoggingIn}
              component={Link}
              to="/"
              data-cy="login-submit-button"
            >
              {newPasswordRequired ? "Change Password" : "Login"}
            </Button>
            {newPasswordRequired ? (
              <Text
                className={styles.passwordRequirementsText}
                data-cy="login-password-requirements-text"
              >
                Password must be at least 8 characters in length and contain a
                number, a capital letter, a lower case letter, and a special
                character.
              </Text>
            ) : null}
          </form>
          <Link
            to={"/forgot-password"}
            className={styles.link}
            data-cy="login-forgot-password-link"
          >
            Forgot Password?
          </Link>
        </Card>
      </Group>
    </div>
  );
};

export default Login;
