import { Group, Image, Text } from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useState } from "react";
import { compressImage } from "../../utils/Image.utils";
import { showErrorNotification } from "../Notifications/Notifications";
import styles from "./ImageUpload.module.css";

export const ImageUpload = ({ setCompressedImage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const handleLoadImage = async (files) => {
    const file = files[0];
    if (!file) {
      showErrorNotification("No file selected");
      return;
    }

    // Create a URL for the image for preview
    setImageSrc(URL.createObjectURL(file));

    setIsLoading(true);
    try {
      const compressedImage = await compressImage(file);
      await setCompressedImage(compressedImage);
    } catch (error) {
      const message = `An error occurred while compressing the image. Error: ${error.toString()}`;
      console.error(message);
      showErrorNotification(message);
      setImageSrc(null); // Reset image preview on error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Dropzone
        className={imageSrc ? styles.dropzoneFull : styles.dropzoneEmpty}
        loading={isLoading}
        onDrop={handleLoadImage}
        accept={[MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.webp]}
      >
        <Group
          position="center"
          spacing="xl"
          className={styles.imageAndTextGroup}
        >
          {imageSrc ? (
            <div className={styles.imageContainer}>
              <Image
                src={imageSrc}
                alt="Preview"
                className={styles.imagePreview}
              />
            </div>
          ) : (
            <Text className={styles.instructions}>
              Drag images here or click to select files
            </Text>
          )}
        </Group>
      </Dropzone>
    </div>
  );
};
