import { Button, Modal } from "@mantine/core";
import { useState } from "react";
import { uploadImageWithLambda } from "../../utils/Image.utils";
import { ImageUpload } from "../ImageUpload/ImageUpload";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../Notifications/Notifications";
import styles from "./Modal.module.css";

export const ImagesModal = ({ entityId, opened, onClose }) => {
  const [compressedImage, setCompressedImage] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);

  const uploadImage = async () => {
    try {
      setIsRequesting(true);
      await uploadImageWithLambda({
        imageFile: compressedImage,
        entityId: entityId,
      });
      showSuccessNotification("Image uploaded successfully!");
      return;
    } catch (error) {
      console.error(error);
      showErrorNotification(
        "An error occurred while uploading the image.",
        error
      );
    } finally {
      onClose();
      setIsRequesting(false);
    }
  };

  return (
    <Modal
      centered
      shadow="md"
      title={`Upload Image`}
      opened={opened}
      onClose={onClose}
    >
      <ImageUpload setCompressedImage={setCompressedImage} />
      <Button
        className={styles.mainButton}
        loading={isRequesting}
        onClick={uploadImage}
      >
        {"Upload"}
      </Button>
    </Modal>
  );
};
