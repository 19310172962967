import { Button, Modal, TextInput } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../pages/DrinkCategories/DrinkCategories.jsx";
import { createEntity, updateEntity } from "../../utils/Api.utils.js";
import { removeNullValues } from "../../utils/Object.utils.js";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../Notifications/Notifications.jsx";
import isValid from "./DrinkModalModal.validation.js";
import styles from "./Modal.module.css";

const DrinkCategoryModal = () => {
  const { opened, open, close, modalInfo, setModalInfo, reload } =
    useContext(ModalContext);

  // Form Data
  const [drinkCategoryData, setDrinkCategoryData] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  // Update the drink category modal to show information when the 'edit' button is clicked on drink category rows
  useEffect(() => {
    if (modalInfo) {
      open();
      setDrinkCategoryData(modalInfo);
    }
    // eslint-disable-next-line
  }, [modalInfo]);

  // Actions taken when closing the modal
  const handleClose = () => {
    close();
    setDrinkCategoryData({});
    setModalInfo(null);
    setIsInvalid(false);
  };

  // Adds a different key/value pair to the 'modalInfo' object, without overwritting previous keys/values
  const addToDrinkCategoryData = (keyName, value) => {
    setDrinkCategoryData((prevData) => {
      var data = { ...prevData };
      data[keyName] = value;
      return data;
    });
  };

  // Submits the Drink Category entity to be created or updated
  const submitDrinkCategory = async (event) => {
    event.preventDefault();
    const isFormValid = await isValid(drinkCategoryData);

    if (typeof isFormValid !== "boolean" || !isFormValid) {
      setIsInvalid(isFormValid);
      console.error(
        "There are errors with the drink category form data.",
        isFormValid
      );
      return;
    }

    try {
      console.log("Submitting drink category.");
      setIsRequesting(true);

      // Remove null values
      removeNullValues(drinkCategoryData);

      // Send an update OR create request, depending on how the modal is being used
      const entityName = "drink-categories";
      const response = modalInfo
        ? updateEntity({
            entityName,
            entityId: drinkCategoryData.drink_category_id,
            body: drinkCategoryData,
          })
        : createEntity({ entityName, body: drinkCategoryData });

      response
        .then((data) => {
          setIsRequesting(false);

          if (data.statusCode === 200) {
            handleClose();
            showSuccessNotification(
              `Drink Category was ${
                modalInfo ? "updated" : "created"
              } successfully!`
            );
            reload();
          } else {
            showErrorNotification(data.data.message);
          }
        })
        .catch(() => {
          setIsRequesting(false);
          showErrorNotification();
        });
    } catch (error) {
      console.error("An error occurred.", error);
      setIsRequesting(false);
      showErrorNotification();
    }
  };

  return (
    <Modal
      centered
      shadow="md"
      title={`${modalInfo ? "Update" : "Add"} Drink Category`}
      opened={opened}
      onClose={handleClose}
    >
      <TextInput
        required
        label="Drink Category Name"
        placeholder="Name"
        value={
          drinkCategoryData && drinkCategoryData.name
            ? drinkCategoryData.name
            : ""
        }
        error={isInvalid}
        data-autofocus
        onChange={(event) => {
          setIsInvalid(false);
          addToDrinkCategoryData("name", event.target.value);
        }}
      />
      <Button
        className={styles.mainButton}
        loading={isRequesting}
        onClick={submitDrinkCategory}
      >
        {modalInfo ? "Update" : "Create"}
      </Button>
    </Modal>
  );
};

export default DrinkCategoryModal;
