import {
  Button,
  Checkbox,
  Loader,
  Modal,
  MultiSelect,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../pages/Drinks/Drinks.jsx";
import {
  createEntity,
  getEntitiesForList,
  updateEntity,
} from "../../utils/Api.utils.js";
import { doesArrayHaveData } from "../../utils/Array.utils.js";
import { uploadImageWithLambda } from "../../utils/Image.utils.js";
import { removeNullValues } from "../../utils/Object.utils.js";
import { ImageUpload } from "../ImageUpload/ImageUpload.jsx";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../Notifications/Notifications.jsx";
import hasFormErrors from "./DrinkModal.validation.js";
import styles from "./Modal.module.css";

const DrinkModal = () => {
  const { opened, open, close, modalInfo, setModalInfo, reload } =
    useContext(ModalContext);

  // Form Data
  const [compressedImage, setCompressedImage] = useState(null);
  const [drinkData, setDrinkData] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const [isLoadingDrinkCategories, setIsLoadingDrinkCategories] = useState([]);
  const [isLoadingIngredients, setIsLoadingIngredients] = useState(false);

  const [drinkCategories, setDrinkCategories] = useState([]);
  const [ingredients, setIngredients] = useState([]);

  // Update the drink modal to show information when the 'edit' button is clicked on drink rows
  useEffect(() => {
    if (modalInfo) {
      open();
      setDrinkData(modalInfo);
    } else if (!opened) {
      return; // Do nothing if the modal is not open
    }

    loadDrinkCategories();
    loadIngredients();

    // eslint-disable-next-line
  }, [modalInfo, opened]);

  // Requests a list of drink categories
  const loadDrinkCategories = () => {
    setIsLoadingDrinkCategories(true);
    getEntitiesForList({
      path: `/drink-categories`,
    }).then((data) => {
      if (doesArrayHaveData(data)) {
        const drinkCategoryList = data.map((drinkCategory) => {
          return {
            value: drinkCategory.drink_category_id,
            label: drinkCategory.name,
          };
        });
        setDrinkCategories(drinkCategoryList);
      }
      setIsLoadingDrinkCategories(false);
    });
  };

  // Requests a list of suppliers
  const loadIngredients = () => {
    setIsLoadingIngredients(true);
    getEntitiesForList({
      path: `/ingredients`,
    }).then((data) => {
      if (doesArrayHaveData(data)) {
        const ingreidentList = data.map((ingredient) => {
          return {
            value: ingredient.ingredient_id,
            label: ingredient.name,
          };
        });
        setIngredients(ingreidentList);
      }
      setIsLoadingIngredients(false);
    });
  };

  // Actions taken when closing the modal
  const handleClose = () => {
    close();
    setCompressedImage(null);
    setDrinkData({});
    setModalInfo(null);
    setIsInvalid(false);
  };

  // Adds a different key/value pair to the 'modalInfo' object, without overwritting previous keys/values
  const addToDrinkData = (keyName, value) => {
    setDrinkData((prevData) => {
      var data = { ...prevData };
      data[keyName] = value;
      return data;
    });
  };

  // Submits the Drink entity to be created or updated
  const submitDrink = async (event) => {
    event.preventDefault();

    const errorObject = await hasFormErrors(drinkData);

    if (typeof errorObject === "object") {
      setIsInvalid(errorObject);
      console.error(
        "There are errors with the ingredient form data.",
        errorObject
      );
      return;
    }

    try {
      console.log("Submitting ingredient.");
      setIsRequesting(true);

      // Remove null values
      removeNullValues(drinkData);

      // Send an update OR create request, depending on how the modal is being used
      const entityName = "drinks";
      const response = modalInfo
        ? updateEntity({
            entityName,
            entityId: drinkData.drink_id,
            body: drinkData,
          })
        : createEntity({ entityName, body: drinkData });

      response
        .then(async (data) => {
          // If the drink has an image, upload it
          if (compressedImage) {
            try {
              const imageUploadResponse = await uploadImageWithLambda({
                imageFile: compressedImage,
                entityId: data.data[0].drink_id,
              });

              if (!drinkData.image_key) {
                await updateEntity({
                  entityName,
                  entityId: data.data[0].drink_id,
                  body: { image_key: imageUploadResponse.image_key },
                });
              }
            } catch (error) {
              console.error(error.message);
              showErrorNotification(
                `An error occurred while uploading the image.`
              );
            }
          }
          setIsRequesting(false);

          if (data.statusCode === 200) {
            showSuccessNotification(
              `Drink was ${modalInfo ? "updated" : "created"} successfully!`
            );
          }
        })
        .catch(() => {
          setIsRequesting(false);
          showErrorNotification();
        })
        .finally(() => {
          handleClose();
          reload();
        });
    } catch (error) {
      console.error("An error occurred.", error);
      setIsRequesting(false);
      showErrorNotification();
    }
  };

  return (
    <Modal
      centered
      shadow="md"
      title={`${modalInfo ? "Update" : "Add"} Drink`}
      opened={opened}
      onClose={handleClose}
    >
      <ImageUpload setCompressedImage={setCompressedImage} />
      <TextInput
        data-autofocus
        required
        className={styles.input}
        label="Name"
        placeholder="Name"
        error={isInvalid.name}
        value={drinkData && drinkData.name ? drinkData.name : ""}
        onChange={(event) => {
          setIsInvalid(false);
          addToDrinkData("name", event.target.value);
        }}
      />
      <Select
        className={styles.input}
        label="Category"
        placeholder="Category"
        data={drinkCategories}
        rightSection={isLoadingDrinkCategories ? <Loader size={16} /> : null}
        value={
          drinkData && drinkData.drink_category_id
            ? drinkData.drink_category_id
            : ""
        }
        onChange={(value) => {
          setIsInvalid(false);
          addToDrinkData("drink_category_id", value);
        }}
      />
      <Textarea
        className={styles.input}
        label="Description"
        placeholder="Description"
        autosize
        minRows={3}
        value={drinkData && drinkData.description ? drinkData.description : ""}
        onChange={(event) => {
          addToDrinkData("description", event.currentTarget.value);
        }}
      />
      <MultiSelect
        className={styles.input}
        classNames={{ pill: styles.pill }}
        label="Ingredients"
        placeholder="Ingredients"
        clearable
        searchable
        checkIconPosition="right"
        rightSection={isLoadingIngredients ? <Loader size={16} /> : null}
        data={ingredients}
        value={
          drinkData && drinkData.ingredient_ids ? drinkData.ingredient_ids : []
        }
        onChange={(values) => {
          addToDrinkData("ingredient_ids", values);
        }}
      />
      <Checkbox
        className={styles.halfWidth}
        label="Is Seasonal?"
        checked={
          drinkData && drinkData.is_seasonal ? drinkData.is_seasonal : null
        }
        onChange={(event) => {
          addToDrinkData("is_seasonal", Boolean(event.currentTarget.checked));
        }}
      />
      <Button
        className={styles.mainButton}
        loading={isRequesting}
        onClick={submitDrink}
      >
        {modalInfo ? "Update" : "Create"}
      </Button>
    </Modal>
  );
};

export default DrinkModal;
