// Remove null values so AWS does freak TF out....
const removeNullValues = (data) => {
  Object.keys(data).forEach((key) => {
    if (!data[key]) {
      delete data[key];
    }
  });
};

export { removeNullValues };
