import { useDisclosure } from "@mantine/hooks";
import { createContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import SupplierModal from "../../components/Modals/SupplierModal.jsx";
import PageTitle from "../../components/PageTitle/PageTitle.jsx";
import SupplierTable from "../../components/Tables/Suppliers/SupplierTable.jsx";
import { getEntities, getEntitiesCount } from "../../utils/Api.utils.js";
import { calculateOffset } from "../../utils/PageIndex.utils.js";

export const SupplierContext = createContext();
export const ModalContext = createContext();

const Suppliers = () => {
  // Supplier state
  const [search] = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [count, setCount] = useState(1);
  const [activePage, setActivePage] = useState(1);

  // Modal state
  const [opened, { open, close }] = useDisclosure(false);
  const [modalInfo, setModalInfo] = useState();

  // Get the suppliers list when the page loads,
  // anytime a search is entered/changed, or the page changes.
  useEffect(() => {
    getSuppliers();
    // eslint-disable-next-line
  }, [search, activePage]);

  // Get the count of suppliers when the page loads
  // or anytime a search is entered/changed
  useEffect(() => {
    getSupplierCount();
    // eslint-disable-next-line
  }, [search]);

  // Gets and sets a list of suppliers.
  const getSuppliers = () => {
    setIsLoading(true);
    getEntities({
      entityName: "suppliers",
      search: search,
      offset: calculateOffset(activePage),
      limit: 100,
      isActive: null,
    }).then((data) => {
      setSuppliers(data);
      setIsLoading(false);
    });
  };

  // Gets and sets the total count of Suppliers.
  const getSupplierCount = () => {
    getEntitiesCount({
      entitiesName: "suppliers",
      search: search,
    }).then((data) => {
      setCount(data.count);
    });
  };

  return (
    <SupplierContext.Provider value={suppliers}>
      <ModalContext.Provider
        value={{
          opened,
          open,
          close,
          modalInfo,
          setModalInfo,
          reload: getSuppliers,
        }}
      >
        <PageTitle
          title="Supplier List"
          buttonLabel="Add New"
          context={ModalContext}
        />
        <SupplierModal />
        <SupplierTable
          isLoading={isLoading}
          total={Math.ceil(count / 100)}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </ModalContext.Provider>
    </SupplierContext.Provider>
  );
};

export default Suppliers;
