import imageCompression from "browser-image-compression";
import Auth from "./Auth/Auth";

const auth = new Auth();

export const compressImage = async (file) => {
  let compressedFile = file;
  const options = {
    maxSizeMB: 3,
    maxWidthOrHeight: 500,
    useWebWorker: true,
  };

  while (compressedFile.size > 3 * 1024 * 1024) {
    try {
      // Reduce quality or dimensions for each iteration
      options.maxWidthOrHeight -= 100;

      compressedFile = await imageCompression(compressedFile, options);
    } catch (error) {
      console.error(error);
      throw new Error("Error compressing the image.");
    }
  }

  return compressedFile;
};

/**
 * Uploads an image to a specified endpoint (uses the API Gateway endpoint + Lambda)
 */
export const uploadImageWithLambda = async ({ imageFile, entityId }) => {
  // Ensure the image file is provided
  if (!imageFile) {
    throw new Error("No image file provided for upload.");
  }

  // Create FormData and append the image file
  const formData = new FormData();
  formData.append("file", imageFile);

  // Set the Entity-Id header and any other necessary headers
  const headers = new Headers({
    "Entity-Id": entityId,
    "Extension-Type": imageFile.type,
    Authorization: await auth.getSessionToken("idToken"),
  });

  try {
    const uploadUrl = process.env.REACT_APP_BASE_URL + "/images";
    const uploadResponse = await fetch(uploadUrl, {
      method: "POST",
      body: formData,
      headers: headers,
    });

    if (!uploadResponse.ok) {
      throw new Error(`Image upload failed: ${uploadResponse.statusText}`);
    }

    // Assuming the response is JSON. Adjust if your endpoint returns something else.
    const uploadResult = await uploadResponse.json();
    console.log("Image uploaded successfully:", uploadResult);
    return uploadResult;
  } catch (error) {
    console.error("Upload error:", error);
    throw new Error(`Failed to upload image: ${error.message}`);
  }
};
