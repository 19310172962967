import { Flex, Text } from "@mantine/core";
import { CheckCircleOutline, Circle } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useContext, useState } from "react";
import { ModalContext } from "../../../pages/Drinks/Drinks.jsx";
import { deleteEntity, updateEntity } from "../../../utils/Api.utils.js";
import { doesArrayHaveData } from "../../../utils/Array.utils.js";
import { formatDate } from "../../../utils/Date.utils.js";
import { ActionsMenu } from "../../ActionsMenu/ActionsMenu.js";
import ImageWithPlaceholder from "../../ImageWithPlaceholder/ImageWithPlaceholder.js";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../Notifications/Notifications.jsx";
import styles from "./DrinkRow.module.css";

const DrinkRow = ({ drink }) => {
  const { reload } = useContext(ModalContext);

  // This is used so that, after activating/deactivating a row,
  // the indicator immediately shows/hides without a page refresh.
  const [isActive, setIsActive] = useState(drink.is_active);

  /**
   * This acts as an active/inactive toggle for the drink
   */
  const handleActiveStatus = () => {
    const entityName = "drinks";
    updateEntity({
      entityName,
      entityId: drink.drink_id,
      body: { is_active: !isActive },
    })
      .then((data) => {
        if (data.statusCode === 200) {
          setIsActive((prev) => !prev);
          showSuccessNotification(
            `${drink.name} was successfully ${
              data.is_active ? "activated!" : "deactivated!"
            }`
          );
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(
          `Failed to toggle drink active status. ${error.message}`
        );
      });
  };

  /**
   * This deletes the drink
   */
  const handleDelete = async () => {
    return deleteEntity({
      entityName: "drinks",
      entityId: drink.drink_id,
    })
      .then((data) => {
        if (data.statusCode === 200) {
          showSuccessNotification(`${drink.name} was successfully deleted!`);
          reload();
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(`Failed to delete drink. ${error.message}`);
      });
  };

  return (
    <tr className={styles.row}>
      <td>
        <SvgIcon
          className={isActive ? styles.active : styles.inactive}
          component={Circle}
        />
      </td>
      <td>
        <Flex gap="sm" align="center">
          <ImageWithPlaceholder
            imageKey={drink.image_key}
            avatarText={drink.name}
            size={35}
          />
          <Flex direction={"column"}>
            <Text>{drink.name}</Text>
            <Text className={styles.info}>{drink.drink_category_name}</Text>
          </Flex>
        </Flex>
      </td>
      <td>
        <Text className={styles.info}>{drink.ingredient_names.join(", ")}</Text>
      </td>
      <td>
        <Flex direction={"column"}>
          {drink.is_vegan && (
            <Flex>
              <SvgIcon
                className={styles.healthIcon}
                component={CheckCircleOutline}
              />
              <Text>Vegan</Text>
            </Flex>
          )}
          {drink.is_organic && (
            <Flex>
              <SvgIcon
                className={styles.healthIcon}
                component={CheckCircleOutline}
              />
              <Text>Organic</Text>
            </Flex>
          )}
        </Flex>
      </td>
      <td>
        <Flex direction={"column"}>
          {doesArrayHaveData(drink.allergen_names) &&
            drink.allergen_names.map((name, index) => {
              return (
                <Text key={index} className={styles.info}>
                  {name}
                </Text>
              );
            })}
        </Flex>
      </td>
      <td>
        <Text>{formatDate(drink.updated_at)}</Text>
      </td>
      <td>
        <ActionsMenu
          modalInfo={drink}
          context={ModalContext}
          isActive={isActive}
          handleActiveStatus={handleActiveStatus}
          handleDelete={handleDelete}
        />
      </td>
    </tr>
  );
};

export default DrinkRow;
