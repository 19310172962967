import { Skeleton } from "@mantine/core";
import styles from "./LoadingRows.module.css";

const LoadingRows = ({ numRows, numColumns, hidden, startSpacing }) => {
  return (
    <>
      {Array.from(Array(numRows).keys()).map((num) => {
        return (
          <tr key={`loadingRow=${num}`} className={styles.row}>
            {Array.from(Array(numColumns).keys()).map((columnNum, index) => {
              return hidden && hidden.includes(columnNum) ? (
                <td key={columnNum} />
              ) : (
                <td key={columnNum}>
                  <Skeleton
                    height={10}
                    width={"80%"}
                    radius="xl"
                    className={
                      startSpacing && index === 0
                        ? styles.startSpacing
                        : styles.line
                    }
                  />
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

export default LoadingRows;
