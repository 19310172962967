import { isStringBetweenLength } from "../../utils/String.utils.ts";

const hasFormErrors = async (ingredientData) => {
  const errorObject = {
    name: "Please enter a valid name",
    ingredient_ids: "Please select at least one ingredient",
  };

  if (
    ingredientData &&
    typeof ingredientData === "object" &&
    Object.keys(ingredientData).length > 0
  ) {
    // Validate the name
    const name = ingredientData.name;
    if (isStringBetweenLength(name, 1, 100)) {
      errorObject.name = false;
    }

    // Validate the ingredient ids
    const ingredientIds = ingredientData.ingredient_ids;
    if (ingredientIds && ingredientIds.length > 0) {
      errorObject.ingredient_ids = false;
    }

    // If all values in the errorObject are false, they passed validation
    const allFalse = Object.values(errorObject).every(
      (value) => value === false
    );
    return allFalse ? false : errorObject;
  } else {
    return errorObject;
  }
};

export default hasFormErrors;
