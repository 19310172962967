import Pool from "./Auth/UserPool";

const sessionDataKey = `${Pool.getClientId()}-`;

const getLocalStorage = (key) => {
  try {
    const session = window.localStorage.getItem(sessionDataKey + key);
    if (session && session !== "null") {
      return JSON.parse(session);
    } else {
      return null;
    }
  } catch (error) {
    console.error("An error occurred while retrieving the session.", error);
  }
};

const setLocalStorage = (key, value) => {
  window.localStorage.setItem(sessionDataKey + key, JSON.stringify(value));
};

export { getLocalStorage, setLocalStorage };
