import { isStringBetweenLength } from "../../utils/String.utils.ts";

const isValid = async (drinkCategoryData) => {
  return new Promise((resolve) => {
    const name =
      drinkCategoryData && drinkCategoryData.name
        ? drinkCategoryData.name
        : null;

    if (isStringBetweenLength(name, 2, 50)) {
      resolve(true);
    } else {
      resolve("Drink Category name is invalid");
    }
  });
};

export default isValid;
