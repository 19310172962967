import { Avatar, Flex, Text } from "@mantine/core";
import { Circle } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useContext, useState } from "react";
import { ModalContext } from "../../../pages/DrinkCategories/DrinkCategories.jsx";
import { deleteEntity, updateEntity } from "../../../utils/Api.utils.js";
import { getRandomColor } from "../../../utils/Color.utils.js";
import { formatDate } from "../../../utils/Date.utils.js";
import { ActionsMenu } from "../../ActionsMenu/ActionsMenu.js";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../Notifications/Notifications.jsx";
import styles from "./DrinkCategoryRow.module.css";

const DrinkCategoryRow = ({ drinkCategory }) => {
  const { reload } = useContext(ModalContext);
  const [color] = useState(getRandomColor());

  // This is used so that, after activating/deactivating a row,
  // the indicator immediately shows/hides without a page refresh.
  const [isActive, setIsActive] = useState(drinkCategory.is_active);

  /**
   * This acts as an active/inactive toggle for the drink category
   */
  const handleActiveStatus = () => {
    const entityName = "drink-categories";
    updateEntity({
      entityName,
      entityId: drinkCategory.drink_category_id,
      body: { name: drinkCategory.name, is_active: !isActive },
    })
      .then((data) => {
        if (data.statusCode === 200) {
          setIsActive((prev) => !prev);
          showSuccessNotification(
            `${drinkCategory.name} was successfully ${
              data.is_active ? "activated!" : "deactivated!"
            }`
          );
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(
          `Failed to toggle drink category active status. ${error.message}`
        );
      });
  };

  /**
   * This deletes the drink category
   */
  const handleDelete = async () => {
    return deleteEntity({
      entityName: "drink-categories",
      entityId: drinkCategory.drink_category_id,
    })
      .then((data) => {
        if (data.statusCode === 200) {
          showSuccessNotification(
            `${drinkCategory.name} was successfully deleted!`
          );
          reload();
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(
          `Failed to delete drink category. ${error.message}`
        );
      });
  };

  return (
    <tr className={styles.row}>
      <td>
        <SvgIcon
          className={isActive ? styles.active : styles.inactive}
          component={Circle}
        />
      </td>
      <td>
        <Text>{drinkCategory.drink_category_id}</Text>
      </td>
      <td>
        <Flex gap="sm" align="center">
          <Avatar variant="filled" color={color}>
            {drinkCategory.name.charAt(0)}
          </Avatar>
          <Text>{drinkCategory.name}</Text>
        </Flex>
      </td>
      <td>
        <Text>{formatDate(drinkCategory.updated_at)}</Text>
      </td>
      <td>
        <Text>{formatDate(drinkCategory.created_at)}</Text>
      </td>
      <td>
        <ActionsMenu
          modalInfo={drinkCategory}
          context={ModalContext}
          isActive={isActive}
          handleActiveStatus={handleActiveStatus}
          handleDelete={handleDelete}
        />
      </td>
    </tr>
  );
};

export default DrinkCategoryRow;
