import { useDisclosure } from "@mantine/hooks";
import { createContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { getEntities, getEntitiesCount } from "../../utils/Api.utils.js";
import { calculateOffset } from "../../utils/PageIndex.utils.js";
import AllergenModal from "../../components/Modals/AllergenModal.jsx";
import PageTitle from "../../components/PageTitle/PageTitle.jsx";
import AllergenTable from "../../components/Tables/Allergens/AllergenTable";

export const AllergenContext = createContext();
export const ModalContext = createContext();

const Allergens = () => {
  // Allergen state
  const [search] = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [allergens, setAllergens] = useState([]);
  const [count, setCount] = useState(1);
  const [activePage, setActivePage] = useState(1);

  // Modal state
  const [opened, { open, close }] = useDisclosure(false);
  const [modalInfo, setModalInfo] = useState();

  // Get the allergens list when the page loads,
  // anytime a search is entered/changed, or the page changes.
  useEffect(() => {
    getAllergens();
    // eslint-disable-next-line
  }, [search, activePage]);

  // Get the count of allergens when the page loads
  // or anytime a search is entered/changed
  useEffect(() => {
    getAllergenCount();
    // eslint-disable-next-line
  }, [search]);

  // Gets and sets a list of allergens.
  const getAllergens = () => {
    setIsLoading(true);
    getEntities({
      entityName: "allergens",
      search: search,
      offset: calculateOffset(activePage),
      limit: 100,
      isActive: null,
    }).then((data) => {
      setAllergens(data);
      setIsLoading(false);
    });
  };

  // Gets and sets the total count of Allergens.
  const getAllergenCount = () => {
    getEntitiesCount({
      entitiesName: "allergens",
      search: search,
    }).then((data) => {
      setCount(data.count);
    });
  };

  return (
    <AllergenContext.Provider value={allergens}>
      <ModalContext.Provider
        value={{
          opened,
          open,
          close,
          modalInfo,
          setModalInfo,
          reload: getAllergens,
        }}
      >
        <PageTitle
          title="Allergen List"
          buttonLabel="Add New"
          context={ModalContext}
        />
        <AllergenModal />
        <AllergenTable
          isLoading={isLoading}
          total={Math.ceil(count / 100)}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </ModalContext.Provider>
    </AllergenContext.Provider>
  );
};

export default Allergens;
