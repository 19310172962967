import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoutes from "../components/PrivateRoutes/PrivateRoutes";
import Allergens from "../pages/Allergens/Allergens";
import DrinkCategories from "../pages/DrinkCategories/DrinkCategories";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import IngredientCategories from "../pages/IngredientCategories/IngredientCategories";
import Ingredients from "../pages/Ingredients/Ingredients";
import Login from "../pages/Login/Login";
import Suppliers from "../pages/Suppliers/Suppliers";
import styles from "./App.module.css";
import Drinks from "../pages/Drinks/Drinks";

function App() {
  return (
    <div className={styles.app}>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route exact path="/" element={<Drinks />} />
            <Route exact path="/drinks" element={<Drinks />} />
            <Route exact path="/ingredients" element={<Ingredients />} />
            <Route exact path="/suppliers" element={<Suppliers />} />
            <Route exact path="/allergens" element={<Allergens />} />
            <Route
              exact
              path="/drink-categories"
              element={<DrinkCategories />}
            />
            <Route
              exact
              path="/ingredient-categories"
              element={<IngredientCategories />}
            />
          </Route>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route path="*" element={<h1>{"404: There is nothing here."}</h1>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
