import { createTheme } from "@mantine/core";

// Main color #5474B4
const chugPrimary = [
  "#eff6fa",
  "#dfe8ef",
  "#bad0e0",
  "#92b7d3",
  "#72a1c7",
  "#5d93c1",
  "#518cbe",
  "#4279a9",
  "#386c97",
  "#275d86",
];

const theme = createTheme({
  fontFamily: "Helvetica, sans-serif",
  colors: {
    chugPrimary,
  },
});

export default theme;
