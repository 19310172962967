import { Text } from "@mantine/core";
import { Circle } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { useContext, useState } from "react";
import { ModalContext } from "../../../pages/Allergens/Allergens.jsx";
import { deleteEntity, updateEntity } from "../../../utils/Api.utils.js";
import { formatDate } from "../../../utils/Date.utils.js";
import { ActionsMenu } from "../../ActionsMenu/ActionsMenu.js";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../Notifications/Notifications.jsx";
import styles from "./AllergenRow.module.css";

const AllergenRow = ({ allergen }) => {
  const { reload } = useContext(ModalContext);

  // This is used so that, after activating/deactivating a row,
  // the indicator immediately shows/hides without a page refresh.
  const [isActive, setIsActive] = useState(allergen.is_active);

  /**
   * This acts as an active/inactive toggle for the allergen
   */
  const handleActiveStatus = () => {
    const entityName = "allergens";
    updateEntity({
      entityName,
      entityId: allergen.allergen_id,
      body: { name: allergen.name, is_active: !isActive }, // included 'name' field because it's required by Api Gateway's body validation
    })
      .then((data) => {
        if (data.statusCode === 200) {
          setIsActive((prev) => !prev);
          showSuccessNotification(
            `${allergen.name} was successfully ${
              data.is_active ? "activated!" : "deactivated!"
            }`
          );
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(
          `Failed to toggle allergen active status. ${error.message}`
        );
      });
  };

  /**
   * This deletes the allergen
   */
  const handleDelete = async () => {
    return deleteEntity({
      entityName: "allergens",
      entityId: allergen.allergen_id,
    })
      .then((data) => {
        if (data.statusCode === 200) {
          showSuccessNotification(`${allergen.name} was successfully deleted!`);
          reload();
        } else if (data.statusCode === 400) {
          showErrorNotification(data.data.message);
        }
      })
      .catch((error) => {
        showErrorNotification(`Failed to delete allergen. ${error.message}`);
      });
  };

  return (
    <tr className={styles.row}>
      <td>
        <SvgIcon
          className={isActive ? styles.active : styles.inactive}
          component={Circle}
        />
      </td>
      <td>
        <Text>{allergen.allergen_id}</Text>
      </td>
      <td>
        <Text>{allergen.name}</Text>
      </td>
      <td>
        <Text>{formatDate(allergen.updated_at)}</Text>
      </td>
      <td>
        <Text>{formatDate(allergen.created_at)}</Text>
      </td>
      <td>
        <ActionsMenu
          modalInfo={allergen}
          context={ModalContext}
          isActive={isActive}
          handleActiveStatus={handleActiveStatus}
          handleDelete={handleDelete}
        />
      </td>
    </tr>
  );
};

export default AllergenRow;
